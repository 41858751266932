<template>
  <v-dialog
    v-model="visible"
    :max-width="800"
    scrollable
  >
    <v-card>
      <Header @close="close" />

      <v-card-title>
        병원입점신청 상세
      </v-card-title>

      <HospitalApplyDetailForm ref="refHospitalApplyDetailForm" :detailData="detailData" />

      <!-- <BtnBox v-if="detailData"
        :isShowBtnProduct="false"
        :dataId="detailData.hospitalApply_id"
        @save="save"
      /> -->
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
import Header from "./comp/Header.vue";
import BtnBox from "./comp/BtnBox.vue";
import HospitalApplyDetailForm from "./form/HospitalApplyDetailForm.vue";
// const modelService = require("@/utils/dataModel");
// const requireService = require("@/utils/requiredValue.js");
// const rulesService = require("@/utils/rules.js");

export default {
  components: {
    Header,
    BtnBox,
    HospitalApplyDetailForm,
  },
  data() {
    return {
      visible: false,
      detailData: null, //modelService.bbsReqDataModel(),
    };
  },
  methods: {
    Open(detailData) {
      this.visible = true;
      this.detailData = detailData;

      setTimeout(() => {
        // this.$refs.refBbsAddForm.$refs.refBbsInfo._reset(bbsData.bbsGbn);
        // this.$refs.refBbsAddForm.$refs.refBbsInfo.$refs.refTitleField.$refs.refTitle.focus();
      }, 500);
    },
    close() {
      this.visible = false;
    },
    /** 저장/수정/삭제  */
    async save() {
      const $h = this.$helper;
      // console.log("save", this.bbsData);
      // return;
      let msg = "게시판종류를 선택해주세요";
      if(this.bbsData.bbsGbn === "") {
        $h.showTostMessage("", msg, "error");
        return;
      }
      
      if (!this.$refs.refBbsAddForm.$refs.form.validate()) return;
      const saveLbl = this.bbsData.xpk > 0 ? "Update" : "Save";
      if (!confirm(`${saveLbl}?`)) return;
      this.saveAction(this.bbsData);
    },
    async saveAction(data) {
      const $h = this.$helper;
      data.endpoint = "bbs";
      await this.saveDataQuery(data).then((res) => {
        if (res === undefined) return;
        $h.showTostMessage("", $h.commonMessage("S"), "success");
        this.visible = false;
        this.$emit("complete", res);
      });
    },
    ...mapActions(["getDataQuery", "saveDataQuery"]),
  },
};
</script>
<style lang="scss">
.v-input {
  padding-top: 0 !important;
}
</style>