<template>
  <v-navigation-drawer
    app
    class="shadow-sm rounded-tr-lg rounded-br-lg"
    height="100%"
    v-model="getThemeMode.verticalSidebarDrawer"
    :color="
      $vuetify.theme.dark ? 'dark' : getThemeMode.verticalSidebarDrawerColor
    "
    :expand-on-hover="expandOnHover"
    :mini-variant="getThemeMode.verticalSidebarMini"
    src="undefined"
    :dark="getThemeMode.verticalSidebarDrawerColor != 'white' ? true : false"
    :disable-resize-watcher="false"
    :mobile-breakpoint="960"
    :floating="true"
    :right="$vuetify.rtl"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img :style="{ opacity: opacity }" v-bind="props" class="test" />
    </template>

    <vue-perfect-scrollbar
      :settings="{ suppressScrollX: true, wheelPropagation: false }"
      class="h-100 rtl-ps-none ps scroll"
      style="height: 100%"
    >
      <!-- 탑이미지 처리 -->
      <TopImage :getThemeMode="getThemeMode" @toggleSidebar="toggleSidebar" />

      <!-- 메뉴처리  -->
      <v-list>
        <div class="version-info">V : {{ versionInfo }}</div>
        <template v-for="(item, i) in computedItems">
          <!--  -->
          <!-- {{ item }} -->
          <base-item
            v-if="!item.subheader && !item.children"
            :key="`item-${i}`"
            :item="item"
            :alertDot="alertDot"
            @moveToQnAEmit="moveToQnA"
          />
        </template>
      </v-list>
    </vue-perfect-scrollbar>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { items } from "../../data/navigation";
import TopImage from "./TopImage.vue";

export default {
  components: {
    TopImage,
  },
  data() {
    return {
      drawer: true,
      switch1: "on",
      color: "dark",
      right: false,
      permanent: true,
      miniVariant: false,
      expandOnHover: true,
      items: items, //this.$i18n.locale === "ko" ? itemsKo : items,
      opacity: 0.4,
      alertDot: false,
      isDev: process.env.NODE_ENV === "development",
    };
  },
  created() {
    // 재로그인시 verticalSidebarMini =  false로 변경되는 이슈 수정을 위해 조건문 추가함
    if (!this.getThemeMode.verticalSidebarMini) {
      this.expandOnHover = this.getThemeMode.verticalSidebarMini;
    }
  },
  computed: {
    ...mapGetters(["getThemeMode", "meInfo"]),
    getMiniVariant() {
      return this.miniVariant;
    },
    computedItems() {
      // 개발에서만 노출
      if (!this.isDev) {
        // items.splice(items.findIndex((x) => x.group === "test"), 1)
        // items.splice(items.findIndex((x) => x.group === "qna"), 1)
        // items.splice(items.findIndex((x) => x.group === "addHeadOffice"),1)
      }
      // console.log('this.meInfo.userType', this.meInfo.userType);
      // 병원사용자에 노출 제외
      if (this.meInfo && this.meInfo.userType !== "super") {
        items.splice(items.findIndex((x) => x.group === "setting"),1)
      }
      return items;
    },
    versionInfo() {
      return process.env.VUE_APP_VERSION_CODE;
    },
  },
  methods: {
    ...mapActions(["changeVerticalSidebarDrawer", "changeVerticalSidebarMini"]),
    toggleSidebar() {
      this.changeVerticalSidebarMini();
      this.expandOnHover = this.getThemeMode.verticalSidebarMini;
    },
    moveToQnA() {
      this.alertDot = false;
    },
  },
};
</script>

<style lang="scss">
.version-info {
  position: fixed;
  bottom: 10px;
  left: 10px;
  color: gray;
}
.ps-container {
  position: inherit !important;
}
.app-admin-wrap-layout-1.sidebar-mini {
  .v-toolbar {
    // left: 56px !important;
    // transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
  .v-main {
    padding: 75px 0px 12px 56px !important;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    padding-bottom: 0px !important;
  }
  &.sidebar-close {
    .v-toolbar {
      left: 0px !important;
      transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    }
    .v-main {
      padding: 75px 0px 12px 0px !important;
      transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
      padding-bottom: 0px !important;
    }
  }
}
.v-navigation-drawer--mini-variant .v-list-item > *:not(:first-child) {
  overflow: initial;
}
.v-list-item__title {
  overflow: inherit;
}
.v-list.v-sheet > a.v-list-item {
  padding-left: 16px !important;
}
.v-application--is-ltr .v-list-item__avatar:first-child {
  margin-right: 1px !important;
}
</style>
