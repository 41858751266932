<template>
  <div>
    <v-row> 첨부파일 업로드 </v-row>
    <v-row>
      <PrepareFilesUpload
        ref="refPrepareFilesUpload"
        fileGbn="bbsImg"
        fileHeader="bbsImgFile"
        :propsUploadedFiles="bbsData.uploadFiles"
        @checkTargetFiles="checkTargetFiles"
      />
    </v-row>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import PrepareFilesUpload from "@/components/commonV2/ui/PrepareFilesUpload.vue";
const modelService = require("@/utils/dataModel");

export default {
  components: {
    PrepareFilesUpload,
  },
  props: {
    bbsData: {
      type: [Object, Array],
    }, 
  },
  data() {
    return {
    };
  },
  methods: {
    // 업로드후 업로드 된 데이터 전달 받기
    checkTargetFiles(e, removeOption) {
      this.bbsData.uploadFiles = e;
    },
    ...mapActions(["getDataQuery", "saveDataQuery"]),
  },
};
</script>
<style lang="scss">
.contentsInfos_btnBox {
  display: block !important;
  text-align: center;
}
</style>