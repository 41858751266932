<template>
  <v-card-text>
    <v-form ref="form">
      <v-card-text>
        <v-text-field
          class="pt-3"
          ref="refHospitalName"
          v-model="detailData.hospitalName"
          label="병원명"
          placeholder="병원명"
          disabled
        />

        <v-text-field
          class="pt-3"
          ref="refHospitalDepartment"
          v-model="detailData.hospitalDepartment"
          label="병원진료과"
          placeholder="병원진료과"
          disabled
        />

        <v-text-field
          class="pt-3"
          ref="refIsWantForeignPatient"
          v-model="detailData.isWantForeignPatient"
          label="외국인환자 유치기관 등록여부"
          placeholder="외국인환자 유치기관 등록여부"
          disabled
        />

        <v-text-field
          class="pt-3"
          ref="refWantCountry"
          v-model="detailData.wantCountry"
          label="관심있는 국가"
          placeholder="관심있는 국가"
          disabled
        />

        <v-text-field
          class="pt-3"
          ref="refPosAddress"
          v-model="detailData.posAddress"
          label="병원소재지"
          placeholder="병원소재지"
          disabled
        />

        <v-text-field
          class="pt-3"
          ref="refContactMan"
          v-model="detailData.contactMan"
          label="담당자"
          placeholder="담당자"
          disabled
        />

        <v-text-field
          class="pt-3"
          ref="refContactManPos"
          v-model="detailData.contactManPos"
          label="담당자직책"
          placeholder="담당자직책"
          disabled
        />

        <v-text-field
          class="pt-3"
          ref="refContactManEmail"
          v-model="detailData.contactManEmail"
          label="담당자이메일"
          placeholder="담당자이메일"
          disabled
        />

        <v-text-field
          class="pt-3"
          ref="refContactManCell"
          v-model="detailData.contactManCell"
          label="담당자휴대폰"
          placeholder="담당자휴대폰"
          disabled
        />
      </v-card-text>
    </v-form>
  </v-card-text>
</template>
<script>

export default {
  components: { },
  props: {
    detailData: {
      type: [Object, Array],
    },
  },
  data() {
    return {
    };
  },
  methods: {},
};
</script>
<style lang="scss">
.headTitle {
  font-size: 20px;
  font-weight: 700;
  color: darkblue;
}
.hGap2 {
  height: 50px !important;
}
.hGap {
  height: 20px !important;
}
.subMain {
  width: 100%;
  ._vGap {
    height: 10px;
  }
  .edit_vGap {
    height: 7px;
  }
}
</style>