<template>
  <div class="bookingBox">
    <div class="headTitle">예약정보</div>
    <div class="patient_vGap"></div>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <SelectBox 
            label="예약상태" 
            selectStyleWidth="width: 100%" 
            :value="bookingData.status" 
            :items="statusItems" 
            @changeSelect="changeSelect" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="8">
          <BookingDateTimes :bookings="bookingData.bookings" />
        </v-col>
        <v-col cols="4" class="pt-5">
          <!-- selectedConfirm {{ selectedConfirm }} -->
          <RadioComps :items="confirmData" :prosSelected="selectedConfirm" propsVGap="margin-bottom: 40px" @changeType="changeType" />
        </v-col>
      </v-row>

      <!-- 진료선택 -->
      <v-row>
        <v-col cols="12">
          <SelectBoxProduct 
            ref="refSelectBoxProduct" 
            :selectedId="bookingData.treatments" 
            @changeSelect="productTypeChangeSelect" />
        </v-col>
      </v-row>

      <!-- 견적 -->
      <!-- <v-row>
        <v-col cols="12">
          <TextForm 
            label="견적사항" 
            placeholder=""
            :propsText="bookingData.patientOptionInfo.quot"
            @inputChange="bookingData.patientOptionInfo.quot = $event" />
        </v-col>
      </v-row> -->

      <!-- 진료준비사항 -->
      <v-row>
        <v-col cols="12">
          <TextForm 
            label="진료시 준비사항" 
            placeholder="진료(내원)시 준비하셔야 할 사항입니다."
            :propsText="bookingData.patientOptionInfo.preparation"
            @inputChange="bookingData.patientOptionInfo.preparation = $event" />
        </v-col>
      </v-row>

      <!-- 환자에게 전달 할 문서 -->
      <v-row>
        <v-col cols="12">
          * 환자에게 전달 할 문서(예:비자를 위한 진료문서등등)
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <PatientFilesUpload
            ref="refPatientFilesUpload"
            :ownerData="bookingData"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>
<script>
import SelectBox from "@/components/commonV2/ui/SelectBox.vue";
import BookingDateTimes from "./bookingInfo/BookingDateTimes.vue";
import RadioComps from "@/components/commonV2/ui/RadioComps.vue";
import TextForm from "@/components/commonV2/ui/TextForm.vue";
import SelectBoxProduct from "@/components/commonV2/ui/SelectBoxProduct.vue";
import PatientFilesUpload from "./bookingInfo/PatientFilesUpload.vue";
const rulesService = require("@/utils/rules.js");
const formatService = require("@/utils/format.js");

export default {
  components: { 
    SelectBox, BookingDateTimes, RadioComps, TextForm, 
    SelectBoxProduct,
    PatientFilesUpload },
  props: {
    bookingData: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      filedRules: rulesService.filedRules(),
      statusItems: formatService.statusItems(),
      confirmData: [
        { value: "선택1", label: "희망선택1", color: "" },
        { value: "선택2", label: "희망선택2", color: "" },
      ],
      selectedConfirm: "",
      // selectedProductIds: [],
    }
  },
  watch: {
    bookingData: {
      handler(val, oldVal) {
        this.selectedConfirm = this.checkConfirmValue(val.bookings[0].confirm, val.bookings[1].confirm)
        setTimeout(() => {
          this.$refs.refSelectBoxProduct.getProductData(this.bookingData);
        }, 300);
      },
      immediate: true // 컴포넌트가 생성되자마자 즉시 실행
    }
  },
  methods: {
    checkConfirmValue(dbConfirm1, dbConfirm2) {
      let retVal = "";
      if (dbConfirm1 === 'Y') {
        retVal = "선택1";
      } else if (dbConfirm2 === 'Y') {
        retVal = "선택2"
      } else {
        retVal = ""
      }
      return retVal;
    },
    changeType(e) {
      console.log('e', e);
      if (e === '선택1') {
        this.bookingData.bookings[0].confirm = 'Y';
        this.bookingData.bookings[1].confirm = 'N';
      } else {
        this.bookingData.bookings[0].confirm = 'N';
        this.bookingData.bookings[1].confirm = 'Y';
      }
    },
    // 예약상태
    changeSelect(e) {
      this.bookingData.status = e;
      this.$emit("changeSelect", e);
    },
    // 진료선택
    productTypeChangeSelect(e) {
      this.bookingData.treatments = e;
      console.log('selectedProductIds', e);
    },
  },
}
</script>
<style lang="scss">
.bookingBox {
  width: 800px;
}
</style>