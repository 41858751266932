<template>
  <v-dialog
    v-model="visible"
    :max-width="clinicData && clinicData.hospital_id > 0 ? '1200px' : '600px'"
    scrollable
  >
    <v-card class="editorPadding">
      <Header @close="close" />

      <v-card-title>
        <!-- 병의원 정보 -->
        {{ $t("clinic.title") }}
        {{ clinicData && clinicData.hospital_id > 0 ? $t("common.titleUpdate") : $t("common.titleRegistration") }}
      </v-card-title>

      <ClinicAddForm ref="refClinicAddForm" :clinicData="clinicData" />

      <BtnBox
        :isShowBtnProduct="false"
        :dataId="clinicData.hospital_id"
        :activated="clinicData.activated"
        @managerProduct="managerProduct"
        @save="saveClinic"
        @active="activeClinic"
      />
    </v-card>

    <!-- <DialogClinicProductAdd ref="refDialogClinicProductAdd" /> -->
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
import Header from "./comp/Header.vue";
import BtnBox from "./comp/BtnBox.vue";
import ClinicAddForm from "./form/ClinicAddForm.vue";
const modelService = require("@/utils/dataModel");
const requireService = require("@/utils/requiredValue.js");
const rulesService = require("@/utils/rules.js");

export default {
  components: {
    Header,
    BtnBox,
    ClinicAddForm,
  },
  data() {
    return {
      visible: false,
      cellRules: rulesService.cellRules(),
      emailRules: rulesService.emailRules(),
      requiredMsg: requireService.requiredFieldAlertMessage(),
      reqData: modelService.reqDataModel(),
      clinicData: modelService.hospitalModel(),
    };
  },
  methods: {
    Open(clinicData) {
      console.log("clinicData", clinicData);
      this.visible = true;
      this.reqData.hospital_id = clinicData.hospital_id;
      this.clinicData = clinicData;
      if (clinicData.hospital_id > 0) this.getHospitalData();

      setTimeout(() => {
        this.$refs.refClinicAddForm.$refs.refClinicBasicInfo.$refs.refTitleField.$refs.refTitle.focus();
      }, 500);
    },
    close() {
      this.visible = false;
    },
    activeClinic(option) {
      this.clinicData.activated = option;
      this.saveAction(this.clinicData);
    },
    managerProduct() {
      this.$refs.refDialogClinicProductAdd.Open(this.clinicData);
    },
    /** 데이터읽기  */
    async getHospitalData() {
      const $h = this.$helper;
      this.reqData.endpoint = "hospitalOne";
      await this.getDataQuery(this.reqData).then((res) => {
        if (res === undefined) return;
        let resData = res.data;
        const img = resData.hospitalBiImg;
        if (img !== "" && img !== null) {
          resData.hospitalBiFileInfo = {
            filename: $h.findFileName(img),
            fullURL: img,
            isImageFile: $h.checkImageFile($h.findFileName(img)),
          };
        }
        this.clinicData = resData;

        const tData = this.clinicData.hospitalAddInfo;
        if (tData === null) return;
        // 통역사 처리
        tData.isHaveInterpreterArr = $h.changeStringToArr(tData.isHaveInterpreter);
        tData.payMethodArr = $h.changeStringToArr(tData.payMethod);
        tData.releaseNationArr = $h.changeStringToArr(tData.releaseNation);
        // console.log("", resData);
      });
    },
    /** 저장/수정/삭제  */
    async saveClinic() {
      const $h = this.$helper;
      if (!this.$refs.refClinicAddForm.$refs.form.validate()) return;

      console.log("saveClinic", this.clinicData);
      let msg = "병원종류를 선택해주세요";
      if(this.clinicData.hospitalType === "") {
        $h.showTostMessage("", msg, "info");
        return;
      }
      const saveLbl = this.clinicData.hospital_id > 0 ? "Update" : "Register";
      if (!confirm(`Would you like to ${saveLbl}?`)) return;
      this.saveAction(this.clinicData);
    },
    async saveAction(data) {
      const $h = this.$helper;
      data.endpoint = "hospital";
      if(data.hospitalAddInfo !== null) {
        data.hospitalAddInfo.isHaveInterpreter = data.hospitalAddInfo.isHaveInterpreterArr.toString();
        data.hospitalAddInfo.payMethod = data.hospitalAddInfo.payMethodArr.toString();
        data.hospitalAddInfo.releaseNation = data.hospitalAddInfo.releaseNationArr.toString();
      }
      await this.saveDataQuery(data).then((res) => {
        if (res === undefined) return;
        $h.showTostMessage("", $h.commonMessage("S"), "success");
        this.visible = false;
        this.$emit("complete", res);
      });
    },
    ...mapActions(["getDataQuery", "saveDataQuery"]),
  },
};
</script>
<style lang="scss">
.v-input {
  padding-top: 0 !important;
}
</style>