<template>
  <v-row>
    <v-col>
      <v-card-text class="card-title ma-0">
        게시판 관리
        <!-- 도움말 -->
        <HelpMsg :msgType="helpMsg" />
      </v-card-text>
    </v-col>
    <v-col cols="12" md="12">
      <DateRangeCompWithButton periodOption="R" @complete="changeDateRange" />
    </v-col>

    <v-col cols="12" md="12">
      <v-combobox
        style="width: 200px"
        label="게시판타입(선택)"
        v-model="bbsTypeArr"
        :items="itemsBbsType"
        @change="changeBbsType"
      />
    </v-col>

    <v-col cols="12">
      <TopTable
        btnLabel="신규"
        :btnIsShow="true"
        :IsSwitchShow="true"
        switchLabel="노출"
        :xlsxBtnIsShow="false"
        :data="bbsData"
        @clickEditorBtn="clickNewBtn"
        @clickReload="clickReload"
        @changeSwitch="changeSwitch"
      />
    </v-col>

    <v-col cols="12" class="ma-0 pa-0">
      <DataTable
        ref="DataTable"
        :perPage="10000"
        :tableKey="'rowSeq'"
        :footerHide="true"
        :singleSelect="true"
        :showSelect="false"
        :columns="bbsInfoTableColumns"
        :inputData="bbsData"
        @clickRow="clickRow"
      />
    </v-col>

    <!-- add / edit -->
    <DialogBbsAdd ref="refBbsAdd" @complete="getData" />
  </v-row>
</template>
<script>
import { mapActions } from "vuex";
import TopTable from "@/components/commonV2/ui/TopTable.vue";
import DataTable from "@/components/commonV2/table/DataTable.vue";
import HelpMsg from "@/components/commonV2/helpMsg/HelpMsg.vue";
import SelectBoxClinic from "@/components/commonV2/ui/SelectBoxClinic.vue";
import DateRangeCompWithButton from "@/components/commonV2/ui/DateRangeCompWithButton.vue";
import DialogBbsAdd from "@/components/commonV2/Dialog/DialogBbsAdd.vue";
const modelService = require("@/utils/dataModel");
const formatService = require("@/utils/format.js");

export default {
  components: {
    TopTable,
    DataTable,
    DialogBbsAdd,
    HelpMsg,
    SelectBoxClinic,
    DateRangeCompWithButton,
  },
  data() {
    return {
      helpMsg: "도움말",
      paidData: null,
      reqData: modelService.bbsReqDataModel(),
      defaultDate: [this.$moment().subtract(1, "month"), this.$moment()],
      bbsData: [],
      bbsTypeArr: [],
      itemsBbsType: formatService.itemsBbsType(),
      bbsInfoTableColumns: formatService.bbsInfoTableColumns(),
    };
  },
  created() {
    const $h = this.$helper;
    this.reqData.fromDay = $h.changeDateToString(this.defaultDate[0], false);
    this.reqData.toDay = $h.changeDateToString(this.defaultDate[1], false);
  },
  methods: {
    changeBbsType(e) {
      console.log('e', e);
      this.reqData.bbsGbn = e;
      this.getData();
    },
    clickRow(e) {
      console.log("clickRow", e);
      this.$refs.refBbsAdd.Open(e);
    },
    /**** 중간 ***/
    changeSwitch(e) {
      this.reqData.isDp = e.length === 0 ? "N" : "Y";
      this.getData();
    },
    clickNewBtn() {
      let bbsData = modelService.bbsReqDataModel();
      this.$refs.refBbsAdd.Open(bbsData);
    },
    clickReload() {
      this.reqData.searchVal = "";
      this.selectedBtn = "all"; // 초기화할때 버튼과 date 값을 초기화 해야함
      this.propsDate = [this.$moment().subtract(30, "year"), this.$moment()];
      this.getData();
    },
    /**** 기간 선택 ***/
    changeDateRange(fromDay, toDay) {
      console.log("changeDateRange", fromDay, toDay);
      this.reqData.fromDay = fromDay;
      this.reqData.toDay = toDay;
      this.getData();
    },
    getData() {
      const $h = this.$helper;
      this.bbsData = [];
      this.reqData.endpoint = 'bbs';
      this.getDataQuery(this.reqData).then((resP) => {
        if(resP === undefined) return;
        let res = resP.data;
        if (0 < res.length) {
          res = res.map((item, index) => ({
            ...item,
            no: index + 1,
            titleKo: `${$h.definedStringI18($h.makeStringToArray(item.title, "@@"), 'kr')}`, 
            regDateLabel: $h.changeDateToStringForDp(item.regDate, true),
            dpDateLabel: $h.changeDateToForUpdateDp(item.regDate, item.updDate),
            uploadFileNum: item.uploadFiles.length,
          }));
        }
        this.bbsData = res;

        // 업로드 파일 처리
        this.bbsData.forEach(element => {
          // console.log('element', element.uploadFiles);
          if (0 < element.uploadFiles.length) {
            element.uploadFiles = element.uploadFiles.map((item, index) => ({
              ...item,
              regDateLabel: this.$helper.changeDateToStringForDp(item.regDate,true),
            }));
          }
        });
      })
    },
    clickEdit(item) {
      this.$refs.refDialogPaidHistoryEditor.showModal(item);
    },
    ...mapActions(["getDataQuery", "saveDataQuery"]),
  },
};
</script>