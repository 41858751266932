import i18n from '@/lang/lang';
export const items = [
  //Dashboard
  {
    title: i18n.t('menu.dashBoard'),
    icon: "mdi-monitor-dashboard",
    to: "/dashboard",
    group: "dashboard"
  },
  {
    title: i18n.t('menu.consultingUser'),
    icon: "mdi-account",
    to: "/consulting",
    group: "consulting"
  },
  //Clinic
  {
    title: i18n.t('menu.clinic'),
    icon: "mdi-hospital",
    to: "/clinic",
    group: "clinic"
  },
  //Clinic Staff
  {
    title: "병원관리자 정보",
    icon: "mdi-doctor",
    to: "/staff",
    group: "staff"
  },
  //Patient
  {
    title: i18n.t('menu.patient'),
    icon: "mdi-account",
    to: "/patient",
    group: "patient"
  },
  //정산
  {
    title: i18n.t('menu.billing'),
    icon: "mdi-calculator",
    to: "/billing",
    group: "billing"
  },
  //설정
  {
    title: i18n.t('menu.setting'),
    icon: "mdi-cog",
    to: "/setting",
    group: "setting"
  },
  // 게시판
  {
    title: "게시판관리",
    icon: "mdi-clipboard-clock-outline",
    to: "/bbs",
    group: "bbs"
  },
  // 제휴
  {
    title: "병원입점신청",
    icon: "mdi-gesture-tap-button",
    to: "/hospitalApply",
    group: "hospitalApply"
  },
  // 제휴
  {
    title: "제휴문의",
    icon: "mdi-handshake",
    to: "/coalition",
    group: "coalition"
  },
  // {
  //   title: i18n.t('menu.test'),
  //   icon: "mdi-test-tube",
  //   to: "/test",
  //   group: "test"
  // },
  //Clinic-환자
  // {
  //   title: i18n.t('menu.userBooking'),
  //   icon: "mdi-account",
  //   to: "/userBooking",
  //   group: "userBooking"
  // },
  //Clinic-환자
  //Risk Setting
  // {
  //   title: "Risk Setting",
  //   icon: "mdi-close-octagon",
  //   to: "/risk",
  //   group: "risk"
  // },
  // //Alert Setting
  // {
  //   title: "Alert Setting",
  //   icon: "mdi-alert",
  //   to: "/alert",
  //   group: "alert"
  // },
  // setting -> 공통관리
  // {
  //   title: i18n.t('menu.statistics'),
  //   icon: "mdi-dashboard",
  //   to: "/Statistics",
  //   group: "Statistics"
  // },
  //Device
  // {
  //   title: "Device",
  //   icon: "mdi-devices",
  //   to: "/device",
  //   group: "device"
  // },
  // {
  //   title: i18n.t('menu.contents'),
  //   icon: "mdi-rss-box",
  //   to: "/contents",
  //   group: "contents"
  // },
  //Direct QnA
  // {
  //   title: i18n.t('menu.directQna'),
  //   icon: "mdi-forum",
  //   to: "/qna",
  //   group: "qna"
  // },
  //add headOffice -> 솔루션 사용 주체 
  // {
  //   title: i18n.t('menu.headOffice'), //"Add Head Office",
  //   icon: "mdi-office-building",
  //   to: "/addHeadOffice",
  //   group: "addHeadOffice"
  // },
];