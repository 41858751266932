<template>
  <div>
    <v-row>
      <v-col class="mt-1 mb-2">
        <v-text-field 
          ref="refPatientName" 
          width="100%" 
          label="예약자명" placeholder="예약자명" 
          v-model="bookingData.patientName" />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mt-1 mb-2">
        <RadioComps 
          :prosIsRow="true"
          :items="genderData" 
          :prosSelected="bookingData.gender" 
          @changeType="bookingData.gender = $event" />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mt-1 mb-2">
        <SelectBirthday
          ref="refBirthday"
          :value="bookingData.birthday !== '' && bookingData.birthday !== null? bookingData.birthday.replaceAll('-',''):''"
          @updateSelect="bookingData.birthday = $event"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mt-1 mb-2">
        <SelectBox
          selectStyleWidth="width: 100%"
          ref="refCellNation"
          :items="countryItems"
          :value="bookingData.cellNation"
          @changeSelect="bookingData.cellNation = $event"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mt-1 mb-2">
        <v-text-field 
          ref="refCell" 
          width="100%" 
          label="휴대폰번호(예약시입력)" placeholder="휴대폰번호" 
          v-model="bookingData.cell" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import SelectBox from "@/components/commonV2/ui/SelectBox.vue";
import RadioComps from "@/components/commonV2/ui/RadioComps.vue";
import SelectBirthday from "@/components/commonV2/ui/SelectBirthday.vue";
const formatService = require("@/utils/format.js");
const rulesService = require("@/utils/rules.js");

export default {
  components: { SelectBox, RadioComps, SelectBirthday },
  props: {
    bookingData: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      countryItems: formatService.countryList(),
      genderData: formatService.genderTypeItems(),
      filedRules: rulesService.filedRules(),
    }
  },
}
</script>
<style lang="">

</style>