<template>
  <v-row>
    <v-col>
      <v-card-text class="card-title ma-0">
        병원입점신청 관리
        <!-- 도움말 -->
        <HelpMsg :msgType="helpMsg" />
      </v-card-text>
    </v-col>
    <v-col cols="12" md="12">
      <DateRangeCompWithButton periodOption="R" @complete="changeDateRange" />
    </v-col>

    <v-col cols="12">
      <TopTable
        btnLabel="신규"
        :btnIsShow="true"
        :newBtnIsShow="false"
        :IsSwitchShow="false"
        switchLabel="처리완료"
        :xlsxBtnIsShow="false"
        :data="responseData"
        @clickReload="clickReload"
      />
    </v-col>

    <v-col cols="12" class="ma-0 pa-0">
      <DataTable
        ref="DataTable"
        :perPage="10000"
        :tableKey="'rowSeq'"
        :footerHide="true"
        :singleSelect="true"
        :showSelect="false"
        :columns="tableColumns"
        :inputData="responseData"
        @clickRow="clickRow"
      />
    </v-col>

    <!-- add / edit -->
    <DialogHospitalApplyDetail ref="refDialogHospitalApplyDetail" @complete="getData" />
  </v-row>
</template>
<script>
import { mapActions } from "vuex";
import TopTable from "@/components/commonV2/ui/TopTable.vue";
import DataTable from "@/components/commonV2/table/DataTable.vue";
import HelpMsg from "@/components/commonV2/helpMsg/HelpMsg.vue";
import SelectBoxClinic from "@/components/commonV2/ui/SelectBoxClinic.vue";
import DateRangeCompWithButton from "@/components/commonV2/ui/DateRangeCompWithButton.vue";
import DialogHospitalApplyDetail from "@/components/commonV2/Dialog/DialogHospitalApplyDetail.vue";
const modelService = require("@/utils/dataModel");
const formatService = require("@/utils/format.js");

export default {
  components: {
    TopTable,
    DataTable,
    DialogHospitalApplyDetail,
    HelpMsg,
    SelectBoxClinic,
    DateRangeCompWithButton,
  },
  data() {
    return {
      helpMsg: "도움말",
      defaultDate: [this.$moment().subtract(1, "month"), this.$moment()],
      reqData: modelService.hospitalApplyReqDataModel(),
      responseData: [],
      tableColumns: formatService.hospitalApplyTableColumns(),
    };
  },
  created() {
    const $h = this.$helper;
    this.reqData.fromDay = $h.changeDateToString(this.defaultDate[0], false);
    this.reqData.toDay = $h.changeDateToString(this.defaultDate[1], false);
  },
  methods: {
    clickRow(e) {
      console.log("clickRow", e);
      this.$refs.refDialogHospitalApplyDetail.Open(e);
    },
    clickReload() {
      this.reqData.searchVal = "";
      this.selectedBtn = "all"; // 초기화할때 버튼과 date 값을 초기화 해야함
      this.propsDate = [this.$moment().subtract(30, "year"), this.$moment()];
      this.getData();
    },
    /**** 기간 선택 ***/
    changeDateRange(fromDay, toDay) {
      console.log("changeDateRange", fromDay, toDay);
      this.reqData.fromDay = fromDay;
      this.reqData.toDay = toDay;
      this.getData();
    },
    getData() {
      const $h = this.$helper;
      this.bbsData = [];
      this.reqData.endpoint = 'hospitalApply';
      this.getDataQuery(this.reqData).then((resP) => {
        if(resP === undefined) return;
        let res = resP.data;
        if (0 < res.length) {
          res = res.map((item, index) => ({
            ...item,
            no: index + 1,
            regDateLabel: $h.changeDateToStringForDp(item.regDate, true),
            dpDateLabel: $h.changeDateToForUpdateDp(item.regDate, item.updDate),
            // uploadFileNum: item.uploadFiles.length,
          }));
        }
        this.responseData = res;
      })
    },
    ...mapActions(["getDataQuery", "saveDataQuery"]),
  },
};
</script>