const moment = require("moment");
exports.reqDataModel = () => {
    return {
        endpoint: "",
        fromDay: "",
        toDay: "",
        searchVal: "",
        activated: true,
        serviceType: "",
        diagnosisType: "",
        primaryPhysician_id: "",
        patientType: "consent",
        head_id: 0,
        hospital_id: 0,
        user_id: 0,
    }
}
exports.bbsReqDataModel = () => {
    return {
        endpoint: "",
        xpk: 0,
        fromDay: "",
        toDay: "",
        searchVal: "",
        bbsGbn: "",
        titleGbn: "",
        title: "",
        contents: "",
        isDp: "Y",
        hospital_id: 0
    }
}
exports.coalitionReqDataModel = () => {
    return {
        endpoint: "",
        coalition_id: 0,
        fromDay: "",
        toDay: "",
        searchVal: "",
        bbsGbn: "",
        titleGbn: "",
        title: "",
        contents: "",
        isDp: "Y",
        hospital_id: 0
    }
}
exports.hospitalApplyReqDataModel = () => {
    return {
        endpoint: "",
        hospitalApply_id: 0,
        fromDay: "",
        toDay: "",
        searchVal: "",
        bbsGbn: "",
        titleGbn: "",
        title: "",
        contents: "",
        isDp: "Y",
        hospital_id: 0
    }
}
exports.userModel = () => {
    return {
        user_id: 0,
        userType: '',
        staffType: 'doctor',
        name: '테스트',
        nickname: '',
        loginId: 'm5@predict.kr',
        password: '1234!',
        cell: '01012345678',
        email: '',
        birthdayType: '',
        birthday: '',
        birthtime: '',
        gender: 'MALE',
        userPhotoImg: '',
        signupPath: 'admin',
        activated: true,
        appPush: false,
        marketing: false,
        agreeTerms: true,
        agreePolicy: true,
        updUpk: 1,
        national: "",
        phone: "",
        registationDate: "",
        zipcode: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        emergencyContactName: "",
        emergencyContactPhone: "",
        emergencyContactRelation: "",
        primaryPhysician: 0,
        weight: 0,
        weightUnit: "",
        height: 0,
        heightUnit: ""
    };
};

exports.staffModel = () => {
    return {
        user_id: 0,
        head_id: 0,
        hospital_id: 0,
        userType: 'staff',
        staffType: '',
        checkedLoginId: '',
        duplicateLoginIdChecked: false,
        loginId: "",
        password: "",
        fname: "",
        mname: "",
        lname: "",
        nickname: "",
        description: "",
        userPhotoImg: "",
        signupPath: "admin",
        gender: "FEMALE",
        agreeTerms: true,
        agreePolicy: true,
        appPush: false,
        marketing: false,
        activated: true,
        updUpk: 0,
        cell: "",
        phone: "",
        zipcode: "",
        address1: "",
        address2: "",
        city: "",
        state: ""
    };
};

exports.patientModel = () => {
    return {
        user_id: 0,
        userType: 'patient',
        patientType: 'provisional',
        // careType: [],
        serviceType: "",
        diagnosisType: "",
        icdCode: "",
        icdCodeObj: { icdCode: "" },
        mrn: "",
        dob: "1970-01-01",
        ssn: "",
        checkedSsn: "",
        duplicateChecked: false,
        duplicateCellChecked: false,
        fname: "",
        mname: "",
        lname: "",
        nickname: "",
        description: "",
        userPhotoImg: "",
        signupPath: "admin",
        gender: "MALE",
        agreeTerms: true,
        agreePolicy: true,
        appPush: false,
        marketing: false,
        activated: true,
        updUpk: 0,
        registrationDate: "",
        country: "",
        cell: "",
        checkedMobile: "",
        phone: "",
        zipcode: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        disabledCityAndState: false,
        emergencyContactName: "",
        emergencyContactPhone: "",
        emergencyContactRelation: "",
        primaryPhysician: '',
        weight: 0,
        weightUnit: "kg",
        height: 0,
        heightUnit: "cm",
        patientAddInfo1: {
            nameCn: "",
            passportNumber: "",
            hopeSurgeryDay1: "",
            hopeSurgeryDay2: "",
            wechatID: ""
        },
        patientAddInfo2: {
            surgeryClinic: "",
            surgeryProduct: "",
            prepaid: 0,
            paidInKorea: 0,
            paidUnit: ""
        }
    };
};

exports.patientSampleModel = () => {
    return {
        user_id: 0,
        userType: 'patient',
        // careType: ['CCM'],
        serviceType: "CCM",
        diagnosisType: "HTM",
        mrn: "test0006",
        dob: "19730203",
        ssn: "11111113456",
        checkedSsn: "",
        duplicateChecked: false,
        duplicateCellChecked: false,
        fname: "p-",
        mname: "",
        lname: "test0002",
        nickname: "",
        description: "",
        userPhotoImg: "",
        signupPath: "admin",
        gender: "MALE",
        agreeTerms: true,
        agreePolicy: true,
        appPush: false,
        marketing: false,
        activated: true,
        updUpk: 0,
        registrationDate: "",
        country: "us",
        cell: "123456789",
        checkedMobile: "",
        phone: "",
        zipcode: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        emergencyContactName: "dad",
        emergencyContactPhone: "",
        emergencyContactRelation: "",
        primaryPhysician: '',
        weight: 0,
        weightUnit: "kg",
        height: 0,
        heightUnit: "cm"
        // user_id: 0,
        // userType: 'patient',
        // serviceType: "CCM",
        // careType: ['CCM'],
        // diagnosisType: "HTM",
        // mrn: "test1234",
        // dob: "19710401",
        // ssn: "111112224112",
        // duplicateSSNChecked: true,
        // duplicateMobileChecked: true,
        // fname: "danial23",
        // mname: "",
        // lname: "kang",
        // nickname: "kangPP",
        // description: "설명",
        // userPhotoImg: "",
        // signupPath: "admin",
        // gender: "MALE",
        // agreeTerms: true,
        // agreePolicy: true,
        // appPush: false,
        // marketing: false,
        // activated: true,
        // updUpk: 1,
        // national: "kr",
        // cell: "01012345678",
        // phone: "6722346",
        // registrationDate: "2024-01-20",
        // zipcode: "12346",
        // address1: "address1",
        // address2: "address2",
        // city: "ny",
        // state: "SCHENECTADY23",
        // emergencyContactName: "mom",
        // emergencyContactPhone: "6230989",
        // emergencyContactRelation: "parent",
        // primaryPhysician: 5,
        // weight: 72,
        // weightUnit: "kg",
        // height: 178,
        // heightUnit: "cm"
    };
};

exports.headOfficeModel = () => {
    return {
        head_id: 0,
        officeName: "",
        loginId: "",
        email: "",
        cell: "",
        address: {
            city: "",
            street: "",
            zipcode: "",
            address1: "",
            address1: "",
        },
        activated: true,
        updUpk: 0
    };
};

exports.hospitalModel = () => {
    return {
        endpoint: "hospital",
        head_id: 0,
        hospital_id: 0,
        hospitalName: "",
        hospitalType: "",
        signupPath: "admin",
        email: "",
        homepageUrl: "",
        cell: "",
        eligible: '',
        hospitalPhotoImg: "",
        certifyFiles: "",
        address: {
            city: "",
            street: "",
            zipcode: "",
            address1: "",
            address1: "",
            latitude: "",
            longitude: "",
        },
        hospitalAddInfo: {
            contractDate: "",
            establishmentDate: "",
            isHaveInterpreter: "",
            isHaveInterpreterArr: [],
            payMethod: "",
            payMethodArr: [],
            releaseNation: "",
            releaseNationArr: [],
        },
        hospitalCharge: {
            basicSurgery: 0,
            basicProcedure: 0,
            cnSurgery: 0,
            cnProcedure: 0,
            btSurgery: 0,
            btProcedure: 0,
            useYN: "Y"
        },
        // 진료시간
        salesTime: {
            openTime: "",
            closeTime: "",
            openTime2: "",
            closeTime2: "",
            openTime3: "",
            closeTime3: "",
        },
        hospitalText: "",
        hospitalSpecialText: "",
        ableRoom: "",
        activated: true,
        addUrl: "",
        updUpk: 0
    };
};

exports.hospitalSaleTimesModel = () => {
    return {
        endpoint: "hospitalSaleTimes",
        saleTime_id: 0,
        hospital_id: 0,
        openTime: "10:00",
        lunchStart: "12:00",
        lunchClose: "14:00",
        closeTime: "17:00",
        openTime2: "10:00",
        lunchStart2: "12:00",
        lunchClose2: "14:00",
        closeTime2: "17:00",
        openTime3: "10:00",
        lunchStart3: "12:00",
        lunchClose3: "14:00",
        closeTime3: "17:00",
        isDetail: false,
        mon_openTime: "10:00",
        mon_lunchStart: "12:00",
        mon_lunchClose: "14:00",
        mon_closeTime: "17:00",
        tue_openTime: "10:00",
        tue_lunchStart: "12:00",
        tue_lunchClose: "14:00",
        tue_closeTime: "17:00",
        wed_openTime: "10:00",
        wed_lunchStart: "12:00",
        wed_lunchClose: "14:00",
        wed_closeTime: "17:00",
        thu_openTime: "10:00",
        thu_lunchStart: "12:00",
        thu_lunchClose: "14:00",
        thu_closeTime: "17:00",
        fri_openTime: "10:00",
        fri_lunchStart: "12:00",
        fri_lunchClose: "14:00",
        fri_closeTime: "17:00",
        sat_openTime: "10:00",
        sat_lunchStart: "12:00",
        sat_lunchClose: "14:00",
        sat_closeTime: "17:00",
        sun_openTime: "10:00",
        sun_lunchStart: "12:00",
        sun_lunchClose: "14:00",
        sun_closeTime: "17:00",
        hal_openTime: "10:00",
        hal_lunchStart: "12:00",
        hal_lunchClose: "14:00",
        hal_closeTime: "17:00",
        mon_isClose: true,
        tue_isClose: false,
        wed_isClose: false,
        thu_isClose: false,
        fri_isClose: false,
        sat_isClose: false,
        sun_isClose: false,
        hal_isClose: false,
        commonSat_isClose: false,
        commonSun_isClose: false,
        useYN: "Y",
        updUpk: 0
    };
};

exports.hospitalBookingTypeModel = () => {
    return {
        endpoint: "hospitalBookingType",
        hospital_id: 0,
        bookingTypeValue: "",
        startDay: "",
        endDay: "",
        productTypeUseYN: "N",
        updUpk: 0,
        productTypes: null
    };
};

exports.patientSurveyModel = () => {
    return {
        pageTitle: 'New',
        answerNum: 0,
        ipk: 0,
        user_id: 0,
        groupKey: 0,
        surveyGbn: "M",
        question: "",
        answers: [],
        answerList: [],
        answerResults: [],
        targetDate: "",
        sendType: "email",
        orderNum: 0,
        useYN: "Y",
        isDp: "Y",
        updUpk: 0
    };
};

exports.userCheckDuplModel = () => {
    return {
        hospital_id: 0,
        checkType: "", // mobile / SSN / MRN
        checkValue: ""
    };
};

exports.patientDiagnosisModel = () => {
    return {
        d_id: 0,
        user_id: 0,
        provider_id: '',
        hospital_id: 0,
        service: "",
        diagnosis: "",
        icdCode: "",
        icdCodeObj: { icdCode: "" },
        useYN: "",
        updUpk: 0
    };
};

exports.patientMedicationModel = () => {
    return {
        m_id: 0,
        user_id: 0,
        hospital_id: 0,
        provider_id: '',
        drugName: "",
        activeIngredients: "",
        strength: "",
        route: "",
        dosageForm: "",
        amount: "",
        frequency: "",
        periodStart: "",
        periodEnd: "",
        useYN: "Y",
        updUpk: 0
    };
};

exports.patientAllergyModel = () => {
    return {
        a_id: 0,
        user_id: 0,
        hospital_id: 0,
        mainType: "",
        type: "",
        drugFoodName: "",
        reaction: "",
        severity: "",
        useYN: "Y",
        updUpk: 0
    };
};

exports.patientPreventiveCareModel = () => {
    return {
        pc_id: 0,
        user_id: 0,
        hospital_id: 0,
        mainType: "",
        item: "",
        howOften: "",
        shot: "",
        appointment: "",
        awv: "",
        checkupDate: "",
        dueDateStart: "",
        dueDateEnd: "",
        completionDate: "", // vaccinated Date
        useYN: "Y",
        updUpk: 0
    };
};

exports.patientEmergencyContactModel = () => {
    return {
        ec_id: 0,
        user_id: 0,
        hospital_id: 0,
        emergencyContactName: "",
        emergencyContactPhone: "",
        emergencyContactRelation: "",
        updUpk: 0
    };
};

exports.patientEncounterModel = () => {
    return {
        e_id: 0,
        user_id: 0,
        hospital_id: 0,
        provider_id: '',
        careCoordinator_id: '',
        visitType: "",
        visitTypeEtc: "",
        serviceDate: "",
        serviceTime: "",
        useYN: "Y",
        updUpk: 0
    };
};

exports.patientKeyBiometricsModel = () => {
    return {
        b_id: 0,
        user_id: 0,
        hospital_id: 0,
        indicator: "",
        indicatorValue: '',
        indicatorUnit: "",
        indicatorCc: '',
        useYN: "Y",
        updUpk: 0
    };
};

exports.patientCareNoteModel = () => {
    return {
        cn_id: 0,
        user_id: 0,
        hospital_id: 0,
        dueDateTimeStart: "",
        dueDateTimeEnd: "",
        careTimeSet: {
            date: "",
            startTime: "",
            endTime: "",
        },
        diffSec: 0,
        program: "",
        activeLog: "",
        activeLogType: "",
        careNote: "",
        useYN: "Y",
        updUpk: 0
    };
};

exports.drugInfoModel = () => {
    return {
        drug_id: 0,
        drugName: "",
        activeIngredients: "",
        strength: "",
        dosageFromSource: "",
        dosageFrom: "",
        route: "",
        useYN: "Y",
        updUpk: 0
    }
}

exports.defaultAddressModel = () => {
    return {
        zipcode: '',
        address1: '',
        address2: '',
        city: '',
        street: '',
    }
}

exports.patientActionCarePlanModel = () => {
    return {
        acp_id: 0,
        user_id: 0,
        hospital_id: 0,
        pcp_id: 0,
        ccmServicePerson: "",
        contactPerson: "",
        socialWork: "",
        careProgram: "",
        goal: "",
        primaryOutcome: "",
        targetGoal: "",
        targetGoalAverage: "",
        userPermit: "Y",
        dueDateStart: "",
        dueDateEnd: "",
        useYN: "Y",
        updUpk: 0
    };
};

exports.uploadFilesModel = () => {
    return {
        endpoint: "uploadFiles",
        f_id: 0,
        hospital_id: 0,
        user_id: 0,
        product_id: 0,
        patient_id: 0,
        fileGbn: "",
        locale: "",
        fileOriginalName: "",
        fileUrl: "",
        consentDate: "",
        useYN: "Y",
        updUpk: 0,
        uploadFiles: null
    };
};

exports.patientMemoModel = () => {
    return {
        endpoint: 'patient/memo',
        m_id: 0,
        user_id: 0,
        hospital_id: 0,
        memo: "",
        useYN: "Y",
        updUpk: 0
    };
};

exports.patientSurveySetModel = () => {
    return {
        endpoint: 'patient/surveySet',
        ss_id: 0,
        user_id: 0,
        hospital_id: 0,
        questionSet: "",
        answers: "",
        useYN: "Y",
        updUpk: 0
    };
};

exports.hospitalDirectorsModel = () => {
    return {
        endpoint: 'hospitalDirectors',
        director_id: 0,
        hospital_id: 0,
        directorName: "",
        profileKr: "",
        profileEn: "",
        profileCn: "",
        directorPhotoImage: "",
        useYN: "Y",
        updUpk: 0
    };
};

exports.hospitalManagersModel = () => {
    return {
        endpoint: 'hospitalManagers',
        manager_id: 0,
        hospital_id: 0,
        managerName: "",
        cell: "",
        email: "",
        managerPhotoImage: "",
        messengerId: {
            kakaoTalk: "",
            faceBook: "",
            weChat: "",
            line: "",
            whatsApp: "",
            instagram: ""
        },
        useYN: "Y",
        updUpk: 0
    };
};

exports.hospitalEventModel = () => {
    return {
        endpoint: 'hospitalProduct',
        product_id: 0,
        hospital_id: 0,
        eventName: "",
        itemName: "",
        medicineName: "",
        medicineOrigin: "",
        eventPrice: "",
        eventSalePrice: "",
        products: [],
        isCanTaxRefund: "Y",
        description: "",
        effect: "",
        itemIntro: "",
        useYN: "Y",
        updUpk: 0,
        uploadFiles: null
    };
};

exports.hospitalProductModel = () => {
    return {
        endpoint: 'hospitalProduct',
        product_id: 0,
        hospital_id: 0,
        productName: "",
        productType: "",
        itemName: "",
        medicineName: "",
        medicineOrigin: "",
        productPrice: "",
        productSalePrice: "",
        isCanTaxRefund: "Y",
        description: "",
        effect: "",
        itemIntro: "",
        useYN: "Y",
        updUpk: 0,
        uploadFiles: null
    };
};

exports.hospitalMainImagesModel = () => {
    return {
        endpoint: 'hospitalMainImages',
        image_id: 0,
        hospital_id: 0,
        mainImage: "",
        enterImage: "",
        useYN: "Y",
        updUpk: 0,
        uploadFiles: null
    };
};

exports.productTypeItemModel = () => {
    return {
        endpoint: 'productType',
        type_id: 0,
        item_id: 0,
        hospital_id: 0,
        isCommon: "",
        productType: "",
        productTypeName: "",
        hospitalType: "",
        methodType: "시술",
        useYN: "Y",
        updUpk: 0
    };
};

exports.inquiryReplyModel = () => {
    return {
        endpoint: 'inquiryReply',
        reply_id: 0,
        inquiry_id: 0,
        user_id: 0,
        hospital_id: 0,
        contents: "",
        useYN: "Y",
        isDp: "Y",
        updUpk: 0,
        uploadFiles: null
    };
};

exports.consultingModel = () => {
    return {
        endpoint: 'consulting',
        inquiry_id: 0,
        inquiryType: "i",
        consulting_id: "",
        user_id: 0,
        hospital_id: 0,
        toHospital_id: 0,
        title: "병원답변",
        contents: "",
        quote: "",
        useYN: "Y",
        isAdmin: "Y",
        updUpk: 0,
        uploadFiles: []
    };
};

exports.inquiryDataModel = () => {
    return {
        endpoint: 'inquiry',
        inquiry_id: 0,
        user_id: 0,
        inquiryType: "h",
    };
};