import i18n from '@/lang/lang';
const moment = require("moment");

export const requiredFieldAlertMessage = () => " 필수 입력입니다";

export const tempRiskData = () => {
    return [
        {
            "type": "BG",
            "kind": "PreMeal",
            "veryHigh": 301,
            "high": 130,
            "Low": 70,
            "veryLow": 54,
            "active": 1,
            "upk": 1000001,
            "udate": "2021-12-20 10:10:41",
            "uName": "Administrator "
        },
        {
            "cpk": 9999,
            "type": "BG",
            "kind": "PostMeal",
            "veryHigh": 301,
            "high": 180,
            "Low": 70,
            "veryLow": 54,
            "active": 1,
            "upk": 1000001,
            "udate": "2021-12-20 10:10:41",
            "uName": "Administrator "
        },
        {
            "cpk": 9999,
            "type": "BG",
            "kind": "PreMeal",
            "veryHigh": 301,
            "high": 130,
            "Low": 70,
            "veryLow": 54,
            "active": 1,
            "upk": 1000001,
            "udate": "2021-12-20 10:10:41",
            "uName": "Administrator "
        },
        {
            "cpk": 9999,
            "type": "BP",
            "kind": "Systolic",
            "veryHigh": 170,
            "high": 140,
            "Low": 90,
            "veryLow": 80,
            "active": 1,
            "upk": 1000001,
            "udate": "2021-12-20 10:10:41",
            "uName": "Administrator "
        }
    ]
};

export const tempBPData = () => {
    return [
        {
            "cpk": 9999,
            "type": "BP",
            "kind": "Diastolic",
            "veryHigh": 100,
            "high": 90,
            "Low": 65,
            "veryLow": 55,
            "active": 1,
            "upk": 1000001,
            "udate": "2021-12-20 10:10:40",
            "uName": "Administrator "
        },
    ]
};

export const tempBGData = () => {
    return [
        {
            "MKind": "PreMeal",
            "MDay": "2022-10-28",
            "MDateTimeUTC": "2022/10/28 09:13:00           ",
            "InsDate": "2022/10/28 02:13:00",
            "MValue1": 140,
            "MValue2": null,
            "MValue3": null,
            "dayMValue1": 139,
            "dayMValue2": 0,
            "dayMValue3": 0,
            "indicators": "BLOOD GLUCOSE(PreMeal)"
        },
        {
            "MKind": "PreMeal",
            "MDay": "2022-10-28",
            "MDateTimeUTC": "2022/10/28 09:16:00           ",
            "InsDate": "2022/10/28 02:16:00",
            "MValue1": 139,
            "MValue2": null,
            "MValue3": null,
            "dayMValue1": 139,
            "dayMValue2": 0,
            "dayMValue3": 0,
            "indicators": "BLOOD GLUCOSE(PreMeal)"
        },
        {
            "MKind": "PreMeal",
            "MDay": "2022-10-31",
            "MDateTimeUTC": "2022/10/31 10:03:00           ",
            "InsDate": "2022/10/31 03:03:00",
            "MValue1": 144,
            "MValue2": null,
            "MValue3": null,
            "dayMValue1": 144,
            "dayMValue2": 0,
            "dayMValue3": 0,
            "indicators": "BLOOD GLUCOSE(PreMeal)"
        }
    ]
};

export const tempBpBGData = () => {
    return [
        {
            "cpk": 9999,
            "type": "BP",
            "kind": "Diastolic",
            "veryHigh": 100,
            "high": 90,
            "Low": 65,
            "veryLow": 55,
            "active": 1,
            "upk": 1000001,
            "udate": "2021-12-20 10:10:40",
            "uName": "Administrator "
        },
        {
            "cpk": 9999,
            "type": "BG",
            "kind": "PostMeal",
            "veryHigh": 301,
            "high": 180,
            "Low": 70,
            "veryLow": 54,
            "active": 1,
            "upk": 1000001,
            "udate": "2021-12-20 10:10:41",
            "uName": "Administrator "
        },
        {
            "cpk": 9999,
            "type": "BG",
            "kind": "PreMeal",
            "veryHigh": 301,
            "high": 130,
            "Low": 70,
            "veryLow": 54,
            "active": 1,
            "upk": 1000001,
            "udate": "2021-12-20 10:10:41",
            "uName": "Administrator "
        },
        {
            "cpk": 9999,
            "type": "BP",
            "kind": "Systolic",
            "veryHigh": 170,
            "high": 140,
            "Low": 90,
            "veryLow": 80,
            "active": 1,
            "upk": 1000001,
            "udate": "2021-12-20 10:10:41",
            "uName": "Administrator "
        }
    ]
};

export const tempBpSampleData = () => {
    return [
        [
            {
                "MKind": "",
                "MDay": "2022-10-28",
                "InsDate": "2022/10/28 02:21:00",
                "MValue1": 128,
                "MValue2": 83,
                "MValue3": 60,
                "totBG": 2,
                "totBP": 7,
                "tot": 7
            },
            {
                "MKind": "",
                "MDay": "2022-10-30",
                "InsDate": "2022/10/30 18:16:00",
                "MValue1": 122,
                "MValue2": 75,
                "MValue3": 82,
                "totBG": 2,
                "totBP": 7,
                "tot": 7
            },
            {
                "MKind": "",
                "MDay": "2022-10-31",
                "InsDate": "2022/10/31 03:08:00",
                "MValue1": 116,
                "MValue2": 68,
                "MValue3": 81,
                "totBG": 2,
                "totBP": 7,
                "tot": 7
            },
            {
                "MKind": "",
                "MDay": "2022-11-01",
                "InsDate": "2022/11/01 21:42:00",
                "MValue1": 120,
                "MValue2": 71,
                "MValue3": 98,
                "totBG": 2,
                "totBP": 7,
                "tot": 7
            },
            {
                "MKind": "",
                "MDay": "2022-11-06",
                "InsDate": "2022/11/06 20:24:00",
                "MValue1": 148,
                "MValue2": 96,
                "MValue3": 81,
                "totBG": 2,
                "totBP": 7,
                "tot": 7
            },
            {
                "MKind": "",
                "MDay": "2022-11-16",
                "InsDate": "2022/11/16 15:54:00",
                "MValue1": 137,
                "MValue2": 94,
                "MValue3": 86,
                "totBG": 2,
                "totBP": 7,
                "tot": 7
            },
            {
                "MKind": "",
                "MDay": "2022-11-22",
                "InsDate": "2022/11/22 16:23:00",
                "MValue1": 134,
                "MValue2": 104,
                "MValue3": 89,
                "totBG": 2,
                "totBP": 7,
                "tot": 7
            }
        ],
        [
            {
                "cpk": 9999,
                "type": "BP",
                "kind": "Diastolic",
                "veryHigh": 100,
                "high": 90,
                "Low": 65,
                "veryLow": 55,
                "active": 1,
                "upk": 1000001,
                "udate": "2021-12-20 10:10:40",
                "uName": "Administrator "
            },
            {
                "cpk": 9999,
                "type": "BG",
                "kind": "PostMeal",
                "veryHigh": 301,
                "high": 180,
                "Low": 70,
                "veryLow": 54,
                "active": 1,
                "upk": 1000001,
                "udate": "2021-12-20 10:10:41",
                "uName": "Administrator "
            },
            {
                "cpk": 9999,
                "type": "BG",
                "kind": "PreMeal",
                "veryHigh": 301,
                "high": 130,
                "Low": 70,
                "veryLow": 54,
                "active": 1,
                "upk": 1000001,
                "udate": "2021-12-20 10:10:41",
                "uName": "Administrator "
            },
            {
                "cpk": 9999,
                "type": "BP",
                "kind": "Systolic",
                "veryHigh": 170,
                "high": 140,
                "Low": 90,
                "veryLow": 80,
                "active": 1,
                "upk": 1000001,
                "udate": "2021-12-20 10:10:41",
                "uName": "Administrator "
            }
        ],
        [
            {
                "MKind": "",
                "MDay": "2022-10-28",
                "MDateTimeUTC": "2022/10/28 09:21:00           ",
                "InsDate": "2022/10/28 02:21:00",
                "MValue1": 128,
                "MValue2": 83,
                "MValue3": 60,
                "dayMValue1": 128,
                "dayMValue2": 83,
                "dayMValue3": 60,
                "indicators": "BLOOD PRESSURE"
            },
            {
                "MKind": "",
                "MDay": "2022-10-30",
                "MDateTimeUTC": "2022/10/31 01:16:00           ",
                "InsDate": "2022/10/30 18:16:00",
                "MValue1": 122,
                "MValue2": 75,
                "MValue3": 82,
                "dayMValue1": 122,
                "dayMValue2": 75,
                "dayMValue3": 82,
                "indicators": "BLOOD PRESSURE"
            },
            {
                "MKind": "",
                "MDay": "2022-10-31",
                "MDateTimeUTC": "2022/10/31 10:05:00           ",
                "InsDate": "2022/10/31 03:05:00",
                "MValue1": 120,
                "MValue2": 79,
                "MValue3": 70,
                "dayMValue1": 116,
                "dayMValue2": 68,
                "dayMValue3": 81,
                "indicators": "BLOOD PRESSURE"
            },
            {
                "MKind": "",
                "MDay": "2022-10-31",
                "MDateTimeUTC": "2022/10/31 10:08:00           ",
                "InsDate": "2022/10/31 03:08:00",
                "MValue1": 116,
                "MValue2": 68,
                "MValue3": 81,
                "dayMValue1": 116,
                "dayMValue2": 68,
                "dayMValue3": 81,
                "indicators": "BLOOD PRESSURE"
            },
            {
                "MKind": "",
                "MDay": "2022-11-01",
                "MDateTimeUTC": "2022/11/02 04:42:00           ",
                "InsDate": "2022/11/01 21:42:00",
                "MValue1": 120,
                "MValue2": 71,
                "MValue3": 98,
                "dayMValue1": 120,
                "dayMValue2": 71,
                "dayMValue3": 98,
                "indicators": "BLOOD PRESSURE"
            },
            {
                "MKind": "",
                "MDay": "2022-11-06",
                "MDateTimeUTC": "2022/11/07 04:24:00           ",
                "InsDate": "2022/11/06 20:24:00",
                "MValue1": 148,
                "MValue2": 96,
                "MValue3": 81,
                "dayMValue1": 148,
                "dayMValue2": 96,
                "dayMValue3": 81,
                "indicators": "BLOOD PRESSURE"
            },
            {
                "MKind": "",
                "MDay": "2022-11-06",
                "MDateTimeUTC": "2022/11/07 04:25:00           ",
                "InsDate": "2022/11/06 20:25:00",
                "MValue1": 150,
                "MValue2": 96,
                "MValue3": 80,
                "dayMValue1": 148,
                "dayMValue2": 96,
                "dayMValue3": 81,
                "indicators": "BLOOD PRESSURE"
            },
            {
                "MKind": "",
                "MDay": "2022-11-16",
                "MDateTimeUTC": "2022/11/16 23:54:00           ",
                "InsDate": "2022/11/16 15:54:00",
                "MValue1": 137,
                "MValue2": 94,
                "MValue3": 86,
                "dayMValue1": 137,
                "dayMValue2": 94,
                "dayMValue3": 86,
                "indicators": "BLOOD PRESSURE"
            },
            {
                "MKind": "",
                "MDay": "2022-11-22",
                "MDateTimeUTC": "2022/11/23 00:23:00           ",
                "InsDate": "2022/11/22 16:23:00",
                "MValue1": 134,
                "MValue2": 104,
                "MValue3": 89,
                "dayMValue1": 134,
                "dayMValue2": 104,
                "dayMValue3": 89,
                "indicators": "BLOOD PRESSURE"
            }
        ],
        [
            {
                "MKind": "PreMeal",
                "MDay": "2022-10-28",
                "MDateTimeUTC": "2022/10/28 09:13:00           ",
                "InsDate": "2022/10/28 02:13:00",
                "MValue1": 140,
                "MValue2": null,
                "MValue3": null,
                "dayMValue1": 139,
                "dayMValue2": 0,
                "dayMValue3": 0,
                "indicators": "BLOOD GLUCOSE(PreMeal)"
            },
            {
                "MKind": "PreMeal",
                "MDay": "2022-10-28",
                "MDateTimeUTC": "2022/10/28 09:16:00           ",
                "InsDate": "2022/10/28 02:16:00",
                "MValue1": 139,
                "MValue2": null,
                "MValue3": null,
                "dayMValue1": 139,
                "dayMValue2": 0,
                "dayMValue3": 0,
                "indicators": "BLOOD GLUCOSE(PreMeal)"
            },
            {
                "MKind": "PreMeal",
                "MDay": "2022-10-31",
                "MDateTimeUTC": "2022/10/31 10:03:00           ",
                "InsDate": "2022/10/31 03:03:00",
                "MValue1": 144,
                "MValue2": null,
                "MValue3": null,
                "dayMValue1": 144,
                "dayMValue2": 0,
                "dayMValue3": 0,
                "indicators": "BLOOD GLUCOSE(PreMeal)"
            }
        ]
    ]
};

// export const careTypeOptions = () => {
//     return ['CCM', 'RPM'];
// };

export const activeLogOptions = () => {
    return ['SMS', 'Call', 'E-mail', 'Tele-Medicine', 'Chart Review'];
};

export const activeLogItems = () => {
    return [
        { value: "SMS", label: "SMS", color: "red" },
        { value: "Call", label: "Call", color: "" },
        { value: "E-mail", label: "E-mail", color: "" },
        { value: "Tele-Medicine", label: "Tele-Medicine", color: "" },
        { value: "Chart Review", label: "Chart Review", color: "" },
        { value: "6", label: "Referrals", color: "" },
    ];
};

export const activeLogTypeItems = () => {
    return [
        { value: "1", label: "Care coordination", color: "red" },
        { value: "2", label: "Care Plan / Management Review", color: "" },
        { value: "3", label: "Clinic Follow-up", color: "" },
        { value: "4", label: "Medication Home Coordination", color: "" },
        { value: "5", label: "Patient Education and Support", color: "" },
        { value: "6", label: "Referrals", color: "" },
        {
            value: "7",
            label: "Review of Screening / Preventive Care",
            color: "",
        }
    ];
};

export const certifyFilesItems = () => {
    return [
        { value: "6", label: i18n.t('clinic.certifyFilesItems.qualificationClinicLogoImage'), color: "" },
        { value: "1", label: i18n.t('clinic.certifyFilesItems.businessRegistration'), color: "" },
        { value: "2", label: i18n.t('clinic.certifyFilesItems.foreignPatientsCertify'), color: "" },
        { value: "3", label: i18n.t('clinic.certifyFilesItems.openingCertificate'), color: "" },
        { value: "4", label: i18n.t('clinic.certifyFilesItems.insuranceSubscription'), color: "" },
        { value: "5", label: i18n.t('clinic.certifyFilesItems.qualificationCertificate'), color: "" },
    ];
};

export const staffType = () => {
    let reData = [
        { value: '', text: i18n.t('select.staffType') },
        { value: 'doctor', text: i18n.t('select.staffTypeItems.doctor') },
        { value: 'nurse', text: i18n.t('select.staffTypeItems.Nurse') },
        { value: 'worker', text: i18n.t('select.staffTypeItems.Worker') },
    ]
    return reData;
};

export const userType = () => {
    let reData = [
        { value: 'USER', label: 'USER' },
        { value: 'MANAGER', label: 'MANAGER' },
        { value: 'ADMIN', label: 'ADMIN' },
    ]
    return reData;
};

export const serviceList = () => {
    return [
        { value: '', text: 'Select Service' },
        { value: 'RPM', text: 'RPM' },
        { value: 'CCM', text: 'CCM' },
        { value: 'CCM,RPM', text: 'CCM,RPM' },
    ]
};

export const cptCodeOptions = () => {
    return [
        { value: "99453", text: "99453" },
    ];
};

export const itemsIsHaveInterpreter = () => {
    return ["중국", "일본", "미국"];
};

export const itemsBbsType = () => {
    return ["공지", "뉴스", "FAQ"];
};

export const itemsPayMethod = () => {
    return ["신용카드", "현금"];
};

export const itemsReleaseNation = () => {
    return ["한국", "중국", "미국", "베트남"];
};

export const howOftenList = () => {
    return [
        { value: '', text: 'Select How Often' },
        { value: 'monthly', text: 'Monthly' },
        { value: 'once a year', text: 'Once a year' },
        { value: 'twice a year', text: 'Twice a year' },
        { value: '3 times a year', text: '3 times a year' },
        { value: '4 times a year', text: '4 times a year' },
    ]
};

export const shotList = () => {
    return [
        { value: '', text: 'Select Shot' },
        { value: '1', text: '1Shot' },
        { value: '2', text: '2Shot' },
        { value: '3', text: '3Shot' },
        { value: '4', text: '4Shot' },
        { value: '5', text: '5Shot' },
        { value: '6', text: '6Shot' },
    ]
};

export const statusItems = () => {
    return [
        { value: '', text: 'Select status' },
        { value: '예약', text: '환자예약' },
        { value: '환자취소', text: '환자예약취소' },
        { value: '병원확인', text: '병원확인' },
        { value: '병원예약취소', text: '병원예약취소' },
        { value: '내원', text: '내원' },
        { value: '진료', text: '진료(시술/수술)중' },
        { value: '완료', text: '완료' },
        { value: 'noShow', text: '방문하지안음(NoShow!)' },
    ]
};

export const clickList = () => {
    return [
        { value: '', label: 'Select Clinic' },
    ]
};

export const timeList = () => {
    return [
        { value: '', text: 'Select Time' },
        { value: '00:00', text: '00:00' },
        { value: '00:30', text: '00:30' },
        { value: '01:00', text: '01:00' },
        { value: '01:30', text: '01:30' },
        { value: '02:00', text: '02:00' },
        { value: '02:30', text: '02:30' },
        { value: '03:00', text: '03:00' },
        { value: '03:30', text: '03:30' },
        { value: '04:00', text: '04:00' },
        { value: '04:30', text: '04:30' },
        { value: '05:00', text: '05:00' },
        { value: '05:30', text: '05:30' },
        { value: '06:00', text: '06:00' },
        { value: '06:30', text: '06:30' },
        { value: '07:00', text: '07:00' },
        { value: '07:30', text: '07:30' },
        { value: '08:00', text: '08:00' },
        { value: '08:30', text: '08:30' },
        { value: '09:00', text: '09:00' },
        { value: '09:30', text: '09:30' },
        { value: '10:00', text: '10:00' },
        { value: '10:30', text: '10:30' },
        { value: '11:00', text: '11:00' },
        { value: '11:30', text: '11:30' },
        { value: '12:00', text: '12:00' },
        { value: '12:30', text: '12:30' },
        { value: '13:00', text: '13:00' },
        { value: '13:30', text: '13:30' },
        { value: '14:00', text: '14:00' },
        { value: '14:30', text: '14:30' },
        { value: '15:00', text: '15:00' },
        { value: '15:30', text: '15:30' },
        { value: '16:00', text: '16:00' },
        { value: '16:30', text: '16:30' },
        { value: '17:00', text: '17:00' },
        { value: '17:30', text: '17:30' },
        { value: '18:00', text: '18:00' },
        { value: '18:30', text: '18:30' },
        { value: '19:00', text: '19:00' },
        { value: '19:30', text: '19:30' },
        { value: '20:00', text: '20:00' },
        { value: '20:30', text: '20:30' },
        { value: '21:00', text: '21:00' },
        { value: '21:30', text: '21:30' },
        { value: '22:00', text: '22:00' },
        { value: '22:30', text: '22:30' },
        { value: '23:00', text: '23:00' },
        { value: '23:30', text: '23:30' },
    ]
};

export const diagnosisList = () => {
    return [
        { value: '', text: 'Select Diagnosis' },
        { value: 'HTM', text: 'HTM' },
        { value: 'DM', text: 'DM' },
        { value: 'HTM,DM', text: 'HTM + DM' },
    ]
};

export const BpBgTypeList = () => {
    return [
        { value: 'bp', label: 'BLOOD PRESSURE' },
        { value: 'bg', label: 'BLOOD GLUCOSE' },
    ]
};

export const indicatorItems = () => {
    return [
        { value: '', text: 'Select Indicator' },
        { value: 'weight', text: 'Weight' },
        { value: 'height', text: 'Height' },
        { value: 'bmi', text: 'BMI' },
        { value: 'bp_systolic', text: 'BP > Systolic' },
        { value: 'bp_diastolic', text: 'BP > Diastolic' },
        { value: 'bp_pulseRate', text: 'BP > Pulse Rate' },
        { value: 'hbA1c', text: 'HbA1c' },
    ]
};

export const itemsBookingType = () => {
    return [
        { value: '', text: '[선택]' },
        { value: 'be4w', text: '예약일 전4주' },
        { value: 'be3w', text: '예약일 전3주' },
        { value: 'be2w', text: '예약일 전2주' },
        { value: 'be1w', text: '예약일 전1주' },
        { value: 'be0w', text: '당일예약' },
        { value: '0w', text: '갤린더' },
    ]
};

export const weightList = () => {
    return [
        { value: '', text: 'Select Unit' },
        { value: 'kg', text: 'kg' },
        { value: 'lb', text: 'lb' },
    ]
};

export const heightList = () => {
    return [
        { value: '', text: 'Select Unit' },
        { value: 'feet', text: 'feet' },
        { value: 'inch', text: 'inch' },
    ]
};

export const bmiUnitList = () => {
    return [
        { value: '', text: 'Select Unit' },
        { value: 'kg/m²', text: 'kg/m²' }
    ]
};

export const bpSystolicUnitList = () => {
    return [
        { value: '', text: 'Select Unit' },
        { value: 'mmHg', text: 'mmHg' }
    ]
};

export const bpDiastolicUnitList = () => {
    return [
        { value: '', text: 'Select Unit' },
        { value: 'mmHg', text: 'mmHg' }
    ]
};

export const bpPulseRateUnitList = () => {
    return [
        { value: '', text: 'Select Unit' },
        { value: 'bpm', text: 'bpm' }
    ]
};

export const hbA1cUnitList = () => {
    return [
        { value: '', text: 'Select Unit' },
        { value: '%', text: '%' }
    ]
};

export const patientTypeList = () => {
    return [
        {
            value: 'provisional',
            label: 'Provisional Patient',
        },
        {
            value: 'consent',
            label: 'Consent Patient',
        },
    ]
};

export const itemChargeType = () => {
    return [
        { value: "수술", label: "수술", color: "" },
        { value: "시술", label: "시술", color: "" },
        { value: "이벤트", label: "이벤트", color: "" },
    ]
};

// export const visitTypeList = () => {
//     return [
//         { value: '', text: 'Select VisitType' },
//         { value: 'Doctor Visit', text: 'Doctor Visit' },
//         { value: 'Program Orientation', text: 'Program Orientation' },
//         { value: 'Routine Care', text: 'Routine Care' },
//         { value: 'Vaccinate', text: 'Vaccinate' },
//         { value: 'AWV', text: 'AWV' },
//         { value: 'etc', text: 'Etc' },
//     ]
// };

export const visitTypeItems = () => {
    return [
        { value: '', text: i18n.t('select.visitType') },
        { value: i18n.t('select.visitTypeItems.doctorVisit'), text: i18n.t('select.visitTypeItems.doctorVisit') },
        { value: i18n.t('select.visitTypeItems.programOrientation'), text: i18n.t('select.visitTypeItems.programOrientation') },
        { value: i18n.t('select.visitTypeItems.routineCare'), text: i18n.t('select.visitTypeItems.routineCare') },
        { value: i18n.t('select.visitTypeItems.vaccinate'), text: i18n.t('select.visitTypeItems.vaccinate') },
        { value: i18n.t('select.visitTypeItems.awv'), text: i18n.t('select.visitTypeItems.awv') },
        { value: i18n.t('select.visitTypeItems.other'), text: i18n.t('select.visitTypeItems.other') },
    ]
};

export const countryList = () => {
    return [
        { value: '', text: 'Select COUNTRY' },
        { value: 'cn', text: '중국' },
        { value: 'kr', text: '한국' },
        { value: 'us', text: '미국' },
    ]
};

export const primaryPhysicianList = () => {
    return [
        { value: '', label: 'Select Primary Physician' },
    ]
};

export const ICDCodeList = () => {
    return [
        { value: '', text: 'Select ICD Code' },
    ]
};

export const careProgrammeList = () => {
    return [
        { value: '', text: 'Select Care Programme' },
        { value: 'setA', text: 'Nutrition Care Program for diabetes' },
        { value: 'setB', text: 'Nutrition Care Program Test' },
    ]
};

export const careProgrammeGoalList = () => {
    return [
        { value: '', text: 'Select Care Goal' },
        { value: 'goalA', text: 'Prevention of diabetes through dietary management' },
        { value: 'goalB', text: 'Prevention Test' },
    ]
};

export const primaryOutComeList = () => {
    return [
        { value: '', text: 'Select Primary OutCome' },
        { value: 'hba1c', text: 'HBA1c' },
        { value: 'fbs', text: 'Fasting blood sugar' },
    ]
};

export const userStateType = () => {
    let reData = [
        // { value: '', label: '회원전체' },
        { value: '회원', label: '회원' },
        { value: '탈퇴회원', label: '탈퇴회원' },
    ]

    return reData;
};

export const emergencyContactRelationList = () => {
    return [
        { value: '', text: i18n.t('select.relationType') },
        { value: 'Parent', text: i18n.t('select.relationTypeItems.Parent') },
        { value: 'spouse', text: i18n.t('select.relationTypeItems.spouse') },
        { value: 'grandparent', text: i18n.t('select.relationTypeItems.grandparent') },
        { value: 'adultChild', text: i18n.t('select.relationTypeItems.adultChild') },
        { value: 'sonDaughter', text: i18n.t('select.relationTypeItems.sonDaughter') },
        { value: 'grandChild', text: i18n.t('select.relationTypeItems.grandChild') },
        { value: 'other', text: i18n.t('select.relationTypeItems.other') },
        { value: 'unKnown', text: i18n.t('select.relationTypeItems.unKnown') },
    ]
};

export const answerNumItems = () => {
    return [
        { value: 0, text: 'Select Answer Count' },
        { value: 2, text: '2Ea' },
        { value: 3, text: '3Ea' },
        { value: 4, text: '4Ea' },
        { value: 5, text: '5Ea' },
        { value: 6, text: '6Ea' },
        { value: 7, text: '7Ea' },
        { value: 8, text: '8Ea' },
    ];
};

export const bbsType = () => {
    let reData = [
        { value: '', label: '[게시판형식]' },
        { value: 'notice', label: '공지사항' },
        { value: 'banner', label: '배너관리' },
    ];

    return reData;
};

export const tagOwnerType = () => {
    let reData = [
        { value: '', label: '[선택:생성자]' },
        { value: 'admin', label: '관리자' },
        { value: 'user', label: '사용자' },
    ]

    return reData;
};

export const selectActionStatus = () => {
    let reData = [
        { value: '', label: 'All' },
        { value: '처리필요', label: '처리필요' },
        { value: '처리완료', label: '처리완료' },
    ]

    return reData;
};

export const validateEmail = (email) => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const staffInfoTableColumns = () => {
    let reData = [
        {
            key: 'no',
            title: 'No',
            dataIndex: 'no',
            width: 50,
            scopedSlots: { customRender: 'no' }
        },
        {
            key: 'name',
            title: '이름',
            dataIndex: 'name',
            width: 100,
            scopedSlots: { customRender: 'name' }
        }, {
            key: 'loginId',
            title: '아이디',
            dataIndex: 'loginId',
            scopedSlots: { customRender: 'loginId' }
        },
        // {
        //     key: 'nickname',
        //     title: '닉네임',
        //     dataIndex: 'nickname',
        //     elipsis: true,
        //     scopedSlots: { customRender: 'nickname' }
        // },
        // 골드, 실버, 브론즈
        // {
        //     key: 'userGrade',
        //     title: '등급',
        //     dataIndex: 'userGrade',
        //     width: 80,
        //     scopedSlots: { customRender: 'userGrade' }
        // },
        // {
        //     key: 'firstType',
        //     title: '반려동물종(대표)',
        //     dataIndex: 'firstType',
        //     elipsis: true,
        //     scopedSlots: { customRender: 'firstType' }
        // },
        // {
        //     key: 'petNum',
        //     title: '반려동물보유수',
        //     dataIndex: 'petNum',
        //     width: 100,
        //     scopedSlots: { customRender: 'petNum' }
        // },
        // 탈퇴회원, 정회원
        {
            key: 'staffType',
            title: '구분',
            dataIndex: 'staffType',
            width: 100,
            scopedSlots: { customRender: 'staffType' }
        },
        {
            key: 'isPhoto',
            title: '사진유무',
            dataIndex: 'isPhoto',
            width: 80,
            scopedSlots: { customRender: 'isPhoto' }
        },
        {
            key: 'regDateLabel',
            title: '가입일',
            dataIndex: 'regDateLabel',
            width: 200,
            scopedSlots: { customRender: 'regDateLabel' }
        },
        // {
        //     key: 'EMAIL',
        //     title: 'E-mail',
        //     dataIndex: 'email',
        //     scopedSlots: { customRender: 'EMAIL' }
        // },

    ];
    return reData;
}

export const userInfoTableColumns = () => {
    let reData = [
        {
            key: 'no',
            title: 'No',
            dataIndex: 'no',
            width: 50,
            scopedSlots: { customRender: 'no' }
        },
        {
            key: 'nameFull',
            title: 'Patient Name',
            dataIndex: 'nameFull',
            sorter: true,
            width: 150,
            scopedSlots: { customRender: 'nameFull' }
        },
        // {
        //     key: 'hospital_name',
        //     title: 'clinic',
        //     dataIndex: 'hospital_name',
        //     scopedSlots: { customRender: 'hospital_name' }
        // },
        {
            key: 'genderShort',
            title: 'gender',
            dataIndex: 'genderShort',
            width: 60,
            scopedSlots: { customRender: 'genderShort' }
        },
        {
            key: 'age',
            title: 'Age',
            dataIndex: 'age',
            width: 50,
            scopedSlots: { customRender: 'age' }
        },
        {
            key: 'diagnosisType',
            title: 'Diagnosis',
            dataIndex: 'diagnosisType',
            sorter: true,
            width: 70,
            scopedSlots: { customRender: 'diagnosisType' }
        },
        {
            key: 'serviceType',
            title: 'Service',
            dataIndex: 'serviceType',
            sorter: true,
            width: 70,
            scopedSlots: { customRender: 'serviceType' }
        },
        {
            key: 'CCMLastCall',
            title: 'CCM last call',
            dataIndex: 'CCMLastCall',
            width: 70,
            scopedSlots: { customRender: 'CCMLastCall' }
        },
        {
            key: 'CCMTurn',
            title: 'CCM turn',
            dataIndex: 'CCMTurn',
            width: 70,
            scopedSlots: { customRender: 'CCMTurn' }
        },
        {
            key: 'ccmcareTimeLabel',
            title: 'CCM CareTime',
            dataIndex: 'ccmcareTimeLabel',
            sorter: true,
            width: 98,
            scopedSlots: { customRender: 'ccmcareTimeLabel' }
        },
        {
            key: 'CCMClaims',
            title: 'CCM Claims',
            dataIndex: 'CCMClaims',
            width: 70,
            scopedSlots: { customRender: 'CCMClaims' }
        },
        {
            key: 'CCMSuccessCall',
            title: 'CCM Success Call',
            dataIndex: 'CCMSuccessCall',
            width: 100,
            scopedSlots: { customRender: 'CCMSuccessCall' }
        },
        {
            key: 'CCMFailCall',
            title: 'CCM Fail Call',
            dataIndex: 'CCMFailCall',
            width: 68,
            scopedSlots: { customRender: 'CCMFailCall' }
        },
        {
            key: 'RPMElaspedDate',
            title: 'RPM ElaspedDate',
            dataIndex: 'RPMElaspedDate',
            width: 98,
            scopedSlots: { customRender: 'RPMElaspedDate' }
        },
        {
            key: 'RPMMeasure',
            title: 'RPM Measure',
            dataIndex: 'RPMMeasure',
            sorter: true,
            width: 80,
            scopedSlots: { customRender: 'RPMMeasure' }
        },
        {
            key: 'RPMCareTime',
            title: 'RPM CareTime',
            dataIndex: 'RPMCareTime',
            sorter: true,
            width: 98,
            scopedSlots: { customRender: 'RPMCareTime' }
        },
        {
            key: 'RemainedAlert',
            title: 'Remained Alert',
            dataIndex: 'RemainedAlert',
            sorter: true,
            width: 100,
            scopedSlots: { customRender: 'RemainedAlert' }
        },
        // {
        //     key: 'regDateLabel',
        //     title: '가입일',
        //     dataIndex: 'regDateLabel',
        //     width: 200,
        //     scopedSlots: { customRender: 'regDateLabel' }
        // },
        // {
        //     key: 'EMAIL',
        //     title: 'E-mail',
        //     dataIndex: 'email',
        //     scopedSlots: { customRender: 'EMAIL' }
        // },

    ];
    return reData;
}

export const userInfoTableColumnsV2 = () => {
    let reData = [
        {
            key: 'memo',
            title: 'Memo',
            dataIndex: 'memo',
            ellipsis: true,
            width: 100,
            scopedSlots: { customRender: 'memo' }
        },
        {
            key: 'no',
            title: 'No',
            dataIndex: 'no',
            width: 50,
            scopedSlots: { customRender: 'no' }
        },
        {
            key: 'nameFull',
            title: 'Patient Name',
            dataIndex: 'nameFull',
            sorter: true,
            width: 150,
            scopedSlots: { customRender: 'nameFull' }
        },
        // {
        //     key: 'hospital_name',
        //     title: 'clinic',
        //     dataIndex: 'hospital_name',
        //     scopedSlots: { customRender: 'hospital_name' }
        // },
        {
            key: 'genderShort',
            title: 'gender',
            dataIndex: 'genderShort',
            width: 60,
            scopedSlots: { customRender: 'genderShort' }
        },
        {
            key: 'age',
            title: 'Age',
            dataIndex: 'age',
            width: 50,
            scopedSlots: { customRender: 'age' }
        },
        {
            key: 'diagnosisType',
            title: 'Diagnosis',
            dataIndex: 'diagnosisType',
            sorter: true,
            width: 70,
            scopedSlots: { customRender: 'diagnosisType' }
        },
        {
            key: 'serviceType',
            title: 'Service',
            dataIndex: 'serviceType',
            sorter: true,
            width: 70,
            scopedSlots: { customRender: 'serviceType' }
        },
        {
            key: 'CCMLastCall',
            title: 'CCM last call',
            dataIndex: 'CCMLastCall',
            width: 70,
            scopedSlots: { customRender: 'CCMLastCall' }
        },
        {
            key: 'CCMTurn',
            title: 'CCM turn',
            dataIndex: 'CCMTurn',
            width: 70,
            scopedSlots: { customRender: 'CCMTurn' }
        },
        {
            key: 'ccmcareTimeLabel',
            title: 'CCM CareTime',
            dataIndex: 'ccmcareTimeLabel',
            sorter: true,
            width: 98,
            scopedSlots: { customRender: 'ccmcareTimeLabel' }
        },
        {
            key: 'CCMClaims',
            title: 'CCM Claims',
            dataIndex: 'CCMClaims',
            width: 70,
            scopedSlots: { customRender: 'CCMClaims' }
        },
        {
            key: 'CCMSuccessCall',
            title: 'CCM Success Call',
            dataIndex: 'CCMSuccessCall',
            width: 100,
            scopedSlots: { customRender: 'CCMSuccessCall' }
        },
        {
            key: 'CCMFailCall',
            title: 'CCM Fail Call',
            dataIndex: 'CCMFailCall',
            width: 68,
            scopedSlots: { customRender: 'CCMFailCall' }
        },
        {
            key: 'RPMElaspedDate',
            title: 'RPM ElaspedDate',
            dataIndex: 'RPMElaspedDate',
            width: 98,
            scopedSlots: { customRender: 'RPMElaspedDate' }
        },
        {
            key: 'RPMMeasure',
            title: 'RPM Measure',
            dataIndex: 'RPMMeasure',
            sorter: true,
            width: 80,
            scopedSlots: { customRender: 'RPMMeasure' }
        },
        {
            key: 'RPMCareTime',
            title: 'RPM CareTime',
            dataIndex: 'RPMCareTime',
            sorter: true,
            width: 98,
            scopedSlots: { customRender: 'RPMCareTime' }
        },
        {
            key: 'RemainedAlert',
            title: 'Remained Alert',
            dataIndex: 'RemainedAlert',
            sorter: true,
            width: 100,
            scopedSlots: { customRender: 'RemainedAlert' }
        },
        // {
        //     key: 'regDateLabel',
        //     title: '가입일',
        //     dataIndex: 'regDateLabel',
        //     width: 200,
        //     scopedSlots: { customRender: 'regDateLabel' }
        // },
        // {
        //     key: 'EMAIL',
        //     title: 'E-mail',
        //     dataIndex: 'email',
        //     scopedSlots: { customRender: 'EMAIL' }
        // },

    ];
    return reData;
}

export const clinicInfoTableColumns = () => {
    let reData = [
        {
            key: 'no',
            title: 'No',
            dataIndex: 'no',
            width: 50,
            scopedSlots: { customRender: 'no' }
        },
        {
            key: 'hospitalName',
            title: 'hospital Name',
            dataIndex: 'hospitalName',
            ellipsis: true,
            scopedSlots: { customRender: 'hospitalName' }
        },
        {
            key: 'email',
            title: 'Email',
            dataIndex: 'email',
            width: 200,
            scopedSlots: { customRender: 'email' }
        },
        {
            key: 'cell',
            title: 'Mobile',
            dataIndex: 'cell',
            width: 200,
            scopedSlots: { customRender: 'cell' }
        },
        {
            key: 'regDateLabel',
            title: 'Registration Date',
            dataIndex: 'regDateLabel',
            width: 200,
            scopedSlots: { customRender: 'regDateLabel' }
        },

    ];
    return reData;
}

export const consentTableColumns = () => {
    return [
        {
            key: 'no',
            title: 'No',
            dataIndex: 'no',
            width: 50,
            scopedSlots: { customRender: 'no' }
        },
        {
            key: 'name',
            title: 'Consent Form',
            dataIndex: 'name',
            ellipsis: true,
            scopedSlots: { customRender: 'name' }
        },
        {
            key: 'name2',
            title: '',
            dataIndex: 'name2',
            width: 100,
            scopedSlots: { customRender: 'name2' }
        },
    ];
}

export const medicineTableColumns = () => {
    return [
        { text: "Drug Name", value: "drugName", sortable: true },
        { text: "Route", value: "route", sortable: true, align: "center", width: "80" },
        { text: "Active Ingredients", value: "activeIngredients", sortable: true, align: "left" },
        { text: "Strength", value: "strength", sortable: true, align: "center", width: "100" },
        { text: "Dosage From Source", value: "dosageFromSource", sortable: true, align: "center", width: "180" },
        { text: "Dosage From", value: "dosageFrom", sortable: true, align: "center", width: "120" },
    ];
}

export const messengerItems = () => {
    return [
        { value: "wechat", text: "wechat" },
        { value: "whatsapp", text: "whatsapp" },
        { value: "instagram", text: "instagram" },
        { value: "facebook", text: "facebook" },
        { value: "kakaotalk", text: "kakaotalk" },
        { value: "line", text: "line" },
    ];
};

export const billingConfirmTableColumns = () => {
    return [
        // {
        //     key: 'no',
        //     title: 'No',
        //     dataIndex: 'no',
        //     width: 50,
        //     scopedSlots: { customRender: 'no' }
        // },
        {
            key: 'name',
            title: 'CPT Code',
            dataIndex: 'name',
            width: 100,
            scopedSlots: { customRender: 'name' }
        }, {
            key: 'loginId',
            title: 'Primary Physician',
            dataIndex: 'loginId',
            scopedSlots: { customRender: 'loginId' }
        },
        {
            key: 'isPhoto',
            title: 'Clinic',
            dataIndex: 'isPhoto',
            width: 100,
            scopedSlots: { customRender: 'isPhoto' }
        },
        {
            key: 'regDateLabel',
            title: 'MRN',
            dataIndex: 'regDateLabel',
            width: 100,
            scopedSlots: { customRender: 'regDateLabel' }
        },
        {
            key: 'Route',
            title: 'Patient Name',
            dataIndex: 'Route',
            width: 100,
            scopedSlots: { customRender: 'Route' }
        },
        {
            key: 'cDate',
            title: 'Billing Create Date',
            dataIndex: 'cDate',
            width: 150,
            scopedSlots: { customRender: 'cDate' }
        },
        {
            key: 'Confirm',
            title: 'Confirm',
            dataIndex: 'Confirm',
            width: 100,
            scopedSlots: { customRender: 'Confirm' }
        },
    ];
}

export const billingSummitTableColumns = () => {
    return [
        {
            key: 'name',
            title: 'CPT Code',
            dataIndex: 'name',
            width: 100,
            scopedSlots: { customRender: 'name' }
        }, {
            key: 'loginId',
            title: 'Primary Physician',
            dataIndex: 'loginId',
            scopedSlots: { customRender: 'loginId' }
        },
        {
            key: 'isPhoto',
            title: 'Clinic',
            dataIndex: 'isPhoto',
            width: 100,
            scopedSlots: { customRender: 'isPhoto' }
        },
        {
            key: 'regDateLabel',
            title: 'MRN',
            dataIndex: 'regDateLabel',
            width: 100,
            scopedSlots: { customRender: 'regDateLabel' }
        },
        {
            key: 'Route',
            title: 'Patient Name',
            dataIndex: 'Route',
            width: 100,
            scopedSlots: { customRender: 'Route' }
        },
        {
            key: 'cDate',
            title: 'Billing Create Date',
            dataIndex: 'cDate',
            width: 150,
            scopedSlots: { customRender: 'cDate' }
        },
        {
            key: 'Submitted',
            title: 'Submitted',
            dataIndex: 'Submitted',
            width: 100,
            scopedSlots: { customRender: 'Submitted' }
        },
        {
            key: 'eob',
            title: 'EOB',
            dataIndex: 'eob',
            width: 100,
            scopedSlots: { customRender: 'eob' }
        },
    ];
}

export const userLogTableColumns = () => {
    return [
        { text: "Input Date", value: "regDateLabel", sortable: true, align: "center", width: "180" },
        { text: "IP", value: "earnContent", sortable: true, align: "center", width: "180" },
        { text: "Browser", value: "userAgent", sortable: false, align: "left" },
    ];
}

export const bbsTableColumns = () => {
    let reData = [
        {
            key: 'no',
            title: 'No',
            dataIndex: 'no',
            width: 50,
            scopedSlots: { customRender: 'no' }
        },
        // {
        //     key: 'exposurePeriod',
        //     title: '노출기간',
        //     dataIndex: 'exposurePeriod',
        //     width: 200,
        //     scopedSlots: { customRender: 'exposurePeriod' }
        // },
        // {
        //   key: 'titleGbn',
        //   title: '상품유형',
        //   dataIndex: 'titleGbn',
        //   width: 50,
        //   scopedSlots: { customRender: 'titleGbn' }
        // },
        {
            key: 'bbsGbnLabel',
            title: '게시판유형',
            dataIndex: 'bbsGbnLabel',
            width: 100,
            scopedSlots: { customRender: 'bbsGbnLabel' }
        },
        {
            key: 'title',
            title: '제목',
            dataIndex: 'title',
            ellipsis: true,
            scopedSlots: { customRender: 'title' }
        },
        {
            key: 'contents',
            title: '내용',
            dataIndex: 'contents',
            ellipsis: true,
            scopedSlots: { customRender: 'contents' }
        },
        {
            key: 'isFileLabel',
            title: '상세이미지',
            dataIndex: 'isFileLabel',
            width: 100,
            scopedSlots: { customRender: 'isFileLabel' }
        },
        {
            key: 'regDateLabel',
            title: '등록일',
            dataIndex: 'regDateLabel',
            width: 210,
            scopedSlots: { customRender: 'regDateLabel' }
        }
    ];
    return reData;
}

export const bookingTableColumns = () => {
    let reData = [
        {
            key: 'no',
            title: 'No',
            dataIndex: 'no',
            width: 50,
            scopedSlots: { customRender: 'no' }
        },
        {
            key: 'bookingDay',
            title: '예약일',
            dataIndex: 'bookingDay',
            width: 150,
            scopedSlots: { customRender: 'bookingDay' }
        },
        {
            key: 'bookingTime',
            title: '예약시간',
            dataIndex: 'bookingTime',
            width: 100,
            scopedSlots: { customRender: 'bookingTime' }
        },
        {
            key: 'bbsGbnLabel',
            title: '예약유형',
            dataIndex: 'bbsGbnLabel',
            width: 100,
            scopedSlots: { customRender: 'bbsGbnLabel' }
        },
        {
            key: 'title',
            title: '예약자',
            dataIndex: 'title',
            ellipsis: true,
            scopedSlots: { customRender: 'title' }
        },
        {
            key: 'contents',
            title: '예약내용',
            dataIndex: 'contents',
            ellipsis: true,
            scopedSlots: { customRender: 'contents' }
        },
    ];
    return reData;
}

export const educationTableColumns = () => {
    let reData = [
        {
            key: 'no',
            title: 'No',
            dataIndex: 'no',
            width: 50,
            scopedSlots: { customRender: 'no' }
        },
        {
            key: 'contentType',
            title: '콘텐츠유형',
            dataIndex: 'contentType',
            width: 100,
            scopedSlots: { customRender: 'contentType' }
        },
        {
            key: 'contentName',
            title: '콘텐츠명',
            dataIndex: 'contentName',
            ellipsis: true,
            scopedSlots: { customRender: 'contentName' }
        },
        {
            key: 'contentDes',
            title: '내용',
            dataIndex: 'contentDes',
            ellipsis: true,
            scopedSlots: { customRender: 'contentDes' }
        },
    ];
    return reData;
}

export const measurementTableColumns = () => {
    let reData = [
        {
            key: 'no',
            title: 'No',
            dataIndex: 'no',
            width: 50,
            scopedSlots: { customRender: 'no' }
        },
        {
            key: 'bookingDay',
            title: '측정일시',
            dataIndex: 'bookingDay',
            width: 150,
            scopedSlots: { customRender: 'bookingDay' }
        },
        {
            key: 'bbsGbnLabel',
            title: '측정장비',
            dataIndex: 'bbsGbnLabel',
            width: 100,
            scopedSlots: { customRender: 'bbsGbnLabel' }
        },
        {
            key: 'title',
            title: '측정담당자',
            dataIndex: 'title',
            width: 100,
            scopedSlots: { customRender: 'title' }
        },
        {
            key: 'contents',
            title: '측정예약내용',
            dataIndex: 'contents',
            ellipsis: true,
            scopedSlots: { customRender: 'contents' }
        },
    ];
    return reData;
}

export const consultingTableColumns = () => {
    let reData = [
        {
            key: 'no',
            title: 'No',
            dataIndex: 'no',
            width: 50,
            scopedSlots: { customRender: 'no' }
        },
        {
            key: 'bookingDay',
            title: '상담일시',
            dataIndex: 'bookingDay',
            width: 150,
            scopedSlots: { customRender: 'bookingDay' }
        },
        {
            key: 'title',
            title: '상담담당자',
            dataIndex: 'title',
            width: 100,
            scopedSlots: { customRender: 'title' }
        },
        {
            key: 'contents',
            title: '상담예약내용',
            dataIndex: 'contents',
            ellipsis: true,
            scopedSlots: { customRender: 'contents' }
        },
    ];
    return reData;
}

export const surveyTableColumns = () => {
    return [
        { text: "", value: "", sortable: false, width: "10" },
        { text: "Question", value: "question", sortable: false, align: "left" },
        { text: "Send Type", value: "sendType", sortable: false, align: "center", width: "80" },
        { text: "Target Date", value: "targetDateLabel", sortable: true, align: "center", width: "130" },
        { text: "Last Update", value: "dpDateLabel", sortable: false, align: "center", width: "170" },
    ];
}

export const careNoteTableColumns = () => {
    return [
        { text: "Care Note", value: "careNote", sortable: false },
        { text: "Program", value: "program", sortable: false, align: "center", width: "100" },
        { text: "careTime", value: "diffSecLabel", sortable: true, align: "center", width: "200" },
        { text: "last Update", value: "dpDateLabel", sortable: false, align: "center", width: "200" },
    ];
}

/**
 *
 * @param {moment} startDate
 * @param {moment} endDate
 * @returns startDate와 endDate 사이 날짜의 배열 리턴
 */
export const setDateLabel = (startDate, endDate) => {
    if (startDate.format('YYYYMMDD') === endDate.format('YYYYMMDD')) {
        let time = [];
        for (let i = 0; i <= 23; i++) {
            let hour = i < 10 ? '0' + i : i;
            time.push(hour + ':00');
        }
        return time;
    } else {
        const days = endDate.diff(startDate, 'days');
        const dayRange = Array.from({ length: days + 1 }, (_, i) => i);
        const dateArray = dayRange.map((day) => {
            const date = moment(startDate).add(day, 'days');
            return date.format('YYYY-MM-DD');
        });
        // console.log(dateArray);
        return dateArray;
    }
};

export const defaultChartData = () => {
    let reData = [
        {
            label: 'Visitors',
            backgroundColor: '#f87979',
            data: [],
        },
    ]
    return reData;
};

export const backgroundColorColumn = () => {
    let reData = [
        '#ff6484',
        '#6baed6',
        '#fd8d3c',
        '#74c476',
        '#9e9ac8',
        '#fdbe85',
        '#3182bd',
        '#31a354',
        '#756bb1',
        '#fdae6b',
    ]
    return reData;
};

export const diagnosesTableColumns = () => {
    return [
        { text: i18n.t('column.diagnoses.serviceType'), value: "service", sortable: true, width: "100" },
        { text: i18n.t('column.diagnoses.diagnosis'), value: "diagnosis", sortable: false, align: "left", width: "200" },
        { text: i18n.t('column.diagnoses.icdCode'), value: "icdCode", sortable: false },
        { text: i18n.t('column.diagnoses.provider'), value: "provider_name", sortable: false },
        { text: i18n.t('column.diagnoses.registrationDate'), value: "regDateLabel", sortable: false, width: "200" },
        { text: i18n.t('column.diagnoses.lastUpdate'), value: "dpDateLabel", sortable: false, align: "center", width: "200" },
    ];
}

export const medicationTableColumns = () => {
    return [
        { text: i18n.t('column.medication.drugName'), value: "drugName", sortable: true },
        { text: i18n.t('column.medication.activeIngredients'), value: "activeIngredients", sortable: false, align: "left", width: "200" },
        { text: i18n.t('column.medication.strength'), value: "strength", sortable: false, align: "center", width: "160" },
        { text: i18n.t('column.medication.route'), value: "route", sortable: false, align: "center", width: "100" },
        { text: i18n.t('column.medication.amount'), value: "amount", sortable: false, align: "center", width: "100" },
        { text: i18n.t('column.medication.frequency'), value: "frequency", sortable: false, align: "center", width: "100" },
        { text: i18n.t('column.medication.lastUpdate'), value: "dpDateLabel", sortable: false, align: "center", width: "180" },
    ];
}

export const labTestTableColumns = () => {
    return [
        {
            key: 'no',
            title: 'No',
            dataIndex: 'no',
            width: 50,
            scopedSlots: { customRender: 'no' }
        },
        {
            key: 'DrugName',
            title: 'Test',
            dataIndex: 'DrugName/RPM',
            width: 100,
            scopedSlots: { customRender: 'DrugName' }
        }, {
            key: 'ActiveIngredients',
            title: 'Lab Ref',
            dataIndex: 'ActiveIngredients',
            scopedSlots: { customRender: 'ActiveIngredients' }
        },
        {
            key: 'Strength',
            title: 'Ordered by',
            dataIndex: 'Strength',
            width: 100,
            scopedSlots: { customRender: 'Strength' }
        },
        {
            key: 'Route',
            title: 'Result Status',
            dataIndex: 'Route',
            width: 120,
            scopedSlots: { customRender: 'Route' }
        },
        {
            key: 'regDateLabel',
            title: 'Date',
            dataIndex: 'regDateLabel',
            width: 200,
            scopedSlots: { customRender: 'regDateLabel' }
        },
    ];
}

export const medicationAllergyTableColumns = () => {
    return [
        { text: "Drug Name", value: "drugFoodName", sortable: true, width: "100" },
        { text: "Reaction", value: "reaction", sortable: false, align: "left", width: "200" },
        { text: "Type", value: "type", sortable: false },
        { text: "Severity", value: "severity", sortable: false },
        { text: "registration Date", value: "regDateLabel", sortable: false },
        { text: "Last Update", value: "dpDateLabel", sortable: false, align: "center", width: "180" },
    ];
}

export const foodAllergyTableColumns = () => {
    return [
        { text: i18n.t('column.foodAllergy.drugFoodName'), value: "drugFoodName", sortable: true, width: "100" },
        { text: i18n.t('column.foodAllergy.reaction'), value: "reaction", sortable: false, align: "left", width: "200" },
        { text: i18n.t('column.foodAllergy.type'), value: "type", sortable: false },
        { text: i18n.t('column.foodAllergy.severity'), value: "severity", sortable: false },
        { text: i18n.t('column.foodAllergy.registrationDate'), value: "regDateLabel", sortable: false },
        { text: i18n.t('column.foodAllergy.lastUpdate'), value: "dpDateLabel", sortable: false, align: "center", width: "180" },
    ];
}

export const vaccinateTableColumns = () => {
    return [
        { text: "VACCINATED", value: "itemLabel", sortable: true },
        { text: "HOW OFTEN", value: "howOften", sortable: false, align: "left", width: "150" },
        { text: "SHOT", value: "shot", sortable: false, align: "center", width: "80" },
        { text: "APPOINTMENT", value: "appointmentLabel", sortable: false },
        { text: "VACCINATED DATE", value: "completionDateLabel", sortable: false, width: "180" },
        { text: "Last Update", value: "dpDateLabel", sortable: false, align: "center", width: "180" },
    ];
}

export const annualTableColumns = () => {
    return [
        { text: "Item", value: "itemLabel", sortable: true },
        { text: "HOW OFTEN", value: "howOften", sortable: false, align: "left", width: "150" },
        { text: "SHOT", value: "shot", sortable: false, align: "center", width: "80" },
        { text: "AWV Due Date", value: "awvLabel", sortable: false, align: "left", width: "200" },
        { text: "Last Check-up Date", value: "checkupDateLabel", sortable: false },
        { text: "Completion Date", value: "completionDateLabel", sortable: false, width: "180" },
        { text: "Last Update", value: "dpDateLabel", sortable: false, align: "center", width: "180" },
    ];
}

export const carePlanHistoryTableColumns = () => {
    return [
        {
            key: 'no',
            title: 'No',
            dataIndex: 'no',
            width: 50,
            scopedSlots: { customRender: 'no' }
        },
        {
            key: 'regDateLabel',
            title: 'Update Date',
            dataIndex: 'regDateLabel',
            width: 200,
            scopedSlots: { customRender: 'regDateLabel' }
        },
        {
            key: 'Item',
            title: 'Care Coordinator',
            dataIndex: 'Item',
            width: 200,
            scopedSlots: { customRender: 'Item' }
        }, {
            key: 'HOWOFTEN',
            title: 'Reason of Update',
            dataIndex: 'HOWOFTEN',
            scopedSlots: { customRender: 'HOWOFTEN' }
        },
        {
            key: 'APPOINTMENT',
            title: 'Remark',
            dataIndex: 'APPOINTMENT',
            width: 200,
            scopedSlots: { customRender: 'APPOINTMENT' }
        },
    ];
}

export const surveySetTableColumns = () => {
    return [
        { text: "Survey Set", value: "questionSet", sortable: true, width: "200" },
        { text: "Question Count", value: "surveySetNum", sortable: false, align: "center", width: "150" },
        { text: "", value: "", sortable: false, align: "center", },
        { text: "Complete Date", value: "regDateLabel", sortable: false, align: "center", width: "180" },
    ];
}

export const keyBiometricsTableColumns = () => {
    return [
        { text: "Indicator", value: "indicator", sortable: true, width: "100" },
        { text: "Last Updated Date", value: "dpDateLabel", sortable: false, align: "left", width: "180" },
        { text: "Last Updated Value", value: "lastIndicatorValue", sortable: false, align: "center", width: "200" },
        { text: "Vale", value: "currentIndicatorValue", sortable: false, align: "center", width: "100" },
        { text: "C.C", value: "indicatorCc", sortable: false, align: "center", width: "100" },
        { text: "", value: "", sortable: false, align: "center", },
        { text: "Last Update", value: "dpDateLabel", sortable: false, align: "center", width: "180" },
    ];
}

export const psychosocialTableColumns = () => {
    return [
        {
            key: 'Indicator',
            title: 'Psychosocial & neuropsychological Testing Assessment',
            dataIndex: 'Indicator',
            ellipsis: true,
            scopedSlots: { customRender: 'Indicator' }
        },
        {
            key: 'regDateLabel',
            title: 'Psychosocial Assessment',
            dataIndex: 'regDateLabel',
            width: 200,
            scopedSlots: { customRender: 'regDateLabel' }
        },
        {
            key: 'HOWOFTEN',
            title: 'Psychosocial Risk Detail',
            dataIndex: 'HOWOFTEN',
            scopedSlots: { customRender: 'HOWOFTEN' }
        },
        {
            key: 'APPOINTMENT',
            title: 'Care Guide',
            dataIndex: 'APPOINTMENT',
            width: 200,
            scopedSlots: { customRender: 'APPOINTMENT' }
        },
    ];
}

export const functionalTableColumns = () => {
    return [
        {
            key: 'Indicator',
            title: 'Functional Assessment Testing Assessment',
            dataIndex: 'Indicator',
            ellipsis: true,
            scopedSlots: { customRender: 'Indicator' }
        },
        {
            key: 'regDateLabel',
            title: 'ADL Assessment',
            dataIndex: 'regDateLabel',
            width: 200,
            scopedSlots: { customRender: 'regDateLabel' }
        },
        {
            key: 'HOWOFTEN',
            title: 'Functional Assesses',
            dataIndex: 'HOWOFTEN',
            scopedSlots: { customRender: 'HOWOFTEN' }
        },
        {
            key: 'APPOINTMENT',
            title: 'Care Guide',
            dataIndex: 'APPOINTMENT',
            width: 200,
            scopedSlots: { customRender: 'APPOINTMENT' }
        },
    ];
}

export const vitalSignTableColumns = () => {
    return [
        {
            key: 'indicators',
            title: 'Indicator',
            dataIndex: 'indicators',
            ellipsis: true,
            scopedSlots: { customRender: 'indicators' }
        },
        {
            key: 'date',
            title: 'Date',
            dataIndex: 'date',
            ellipsis: true,
            scopedSlots: { customRender: 'date' }
        },
        {
            key: 'time',
            title: 'Time',
            dataIndex: 'time',
            ellipsis: true,
            scopedSlots: { customRender: 'time' }
        },
        {
            key: 'value',
            title: 'Value',
            dataIndex: 'value',
            width: 100,
            scopedSlots: { customRender: 'value' }
        },
        {
            key: 'heartRate',
            title: 'Heart Rate',
            dataIndex: 'heartRate',
            width: 100,
            scopedSlots: { customRender: 'heartRate' }
        },
    ];
}

export const emergencyContactTableColumns = () => {
    return [
        { text: i18n.t('column.emergencyContact.name'), value: "emergencyContactName", sortable: true, width: "100" },
        { text: i18n.t('column.emergencyContact.relation'), value: "emergencyContactRelation", sortable: false, align: "left", width: "200" },
        // { text: "Name", value: "emergencyContactName", sortable: false, width: "180" },
        { text: i18n.t('column.emergencyContact.number'), value: "emergencyContactPhone", sortable: false, width: "180" },
        { text: "", value: "", sortable: false },
        { text: i18n.t('column.emergencyContact.lastUpdate'), value: "dpDateLabel", sortable: true, align: "center", width: "180" },
    ];
}

export const encounterTableColumns = () => {
    return [
        { text: i18n.t('column.encounter.serviceDate'), value: "serviceDateLabel", sortable: true, width: "100" },
        { text: i18n.t('column.encounter.serviceTime'), value: "serviceTime", sortable: false, align: "center", width: "100" },
        { text: i18n.t('column.encounter.visitType'), value: "visitTypeLbl", sortable: false, align: "left", width: "200" },
        { text: i18n.t('column.encounter.provider'), value: "provider_name", sortable: false },
        { text: i18n.t('column.encounter.careCoordinator'), value: "careCoordinator_name", sortable: false },
        { text: i18n.t('column.encounter.lastUpdate'), value: "dpDateLabel", sortable: true, align: "center", width: "180" },
    ];
}

export const productInfoTableColumns = () => {
    return [
        { text: "상품명", value: "productNameKr", sortable: true, width: "100" },
        { text: "상품타입", value: "productTypeName", sortable: false, align: "center", width: "150" },
        { text: "아이템명", value: "productTypeItemName", sortable: false, align: "center" },
        // { text: "약품/기기명", value: "itemName", sortable: false, align: "center", width: "200" },
        // { text: "약품/기기 원산지", value: "itemOrigin", sortable: false, align: "center" },
        { text: "상품가격", value: "productPriceLabel", sortable: false, align: "center", width: "100" },
        { text: "판매가격", value: "productSalePriceLabel", sortable: false, align: "center", width: "100" },
        { text: "텍스 리펀드", value: "isCanTaxRefund", sortable: false, align: "center", width: "80" },
        { text: "업데이트일", value: "dpDateLabel", sortable: true, align: "center", width: "180" },
    ];
}

export const eventInfoTableColumns = () => {
    return [
        { text: "이벤트명", value: "eventNameKr", sortable: true },
        // { text: "상품타입", value: "productTypeName", sortable: false, align: "center", width: "100" },
        // { text: "아이템명", value: "productTypeItemName", sortable: false, align: "center", width: "100" },
        // { text: "약품/기기명", value: "itemName", sortable: false, align: "center", width: "200" },
        // { text: "약품/기기 원산지", value: "itemOrigin", sortable: false, align: "center" },
        { text: "가격", value: "eventPriceLabel", sortable: true, align: "center", width: "100" },
        { text: "판매가격", value: "eventSalePriceLabel", sortable: true, align: "center", width: "100" },
        { text: "텍스 리펀드", value: "isCanTaxRefund", sortable: false, align: "center", width: "80" },
        { text: "업데이트일", value: "dpDateLabel", sortable: true, align: "center", width: "180" },
    ];
}

export const managerInfoTableColumns = () => {
    return [
        { text: "이름", value: "managerName", sortable: true, width: "100" },
        { text: "전화번호", value: "cell", sortable: false, align: "center", width: "180" },
        { text: "이메일", value: "email", sortable: false, align: "center", width: "100" },
        { text: "메신저계정", value: "topMessengerId", sortable: false, align: "center" },
        { text: "업데이트일", value: "dpDateLabel", sortable: true, align: "center", width: "180" },
    ];
}

export const directorInfoTableColumns = () => {
    return [
        { text: "원장님성함", value: "directorNameKr", sortable: true, align: "center", width: "100" },
        { text: "대표사진", value: "isYNPhoto", sortable: false, align: "center", width: "80" },
        { text: "업데이트일", value: "dpDateLabel", sortable: true, align: "center", width: "180" },
        { text: "프로필(한글)", value: "profile_ko", sortable: false },
    ];
}

export const productTypeTableColumns = () => {
    return [
        { text: "상품구분", value: "productTypeName", sortable: true, align: "left" },
        { text: "아이템수", value: "itemNum", sortable: true, align: "center", width: "100" },
        { text: "", value: "typeAction", sortable: false, align: "center", width: "100" },
        // { text: "업데이트일", value: "dpDateLabel", sortable: true, align: "center", width: "180" },
    ];
}

export const productTypeItemTableColumns = () => {
    return [
        // { text: "시술/수술", value: "methodType", sortable: true, align: "center", width: "80" },
        { text: "상품타입", value: "productTypeName", sortable: true, align: "left", width: "180" },
        { text: "아이템명", value: "productTypeItemName", sortable: false, align: "left" },
        { text: "업데이트일", value: "dpDateLabel", sortable: true, align: "center", width: "180" },
    ];
}

export const rpmIndicator = () => {
    return [
        {
            value: 'all',
            label: 'BLOOD PRESSURE + BLOOD GLUCOSE',
            disabled: false
        }
    ];
}

export const deviceLocation = () => {
    return [
        {
            value: 'At Clinic',
            label: 'At Clinic',
            disabled: false
        },
        {
            value: 'At Head Office',
            label: 'At Head Office',
            disabled: false
        },
        {
            value: 'Waiting for return to Head Office',
            label: 'Waiting for return to Head Office',
            disabled: false
        },
        {
            value: 'Returned to Head Office',
            label: 'Returned to Head Office',
            disabled: false
        },
        {
            value: 'Lost',
            label: 'Lost',
            disabled: false
        },
        {
            value: 'Undefined',
            label: 'Undefined',
            disabled: false
        }
    ];
}

export const deviceUse = () => {
    return [
        {
            value: 'Assigned',
            label: 'Assigned',
            disabled: false
        },
        {
            value: 'UnAssigned',
            label: 'UnAssigned',
            disabled: false
        },
    ];
}

export const deviceFunction = () => {
    return [
        {
            value: 'Functional',
            label: 'Functional',
            disabled: false
        },
        {
            value: 'Faulty',
            label: 'Faulty',
            disabled: false
        },
        {
            value: 'Broken',
            label: 'Broken',
            disabled: false
        },
        {
            value: 'Retired',
            label: 'Retired',
            disabled: false
        },
        {
            value: 'Refurbished',
            label: 'Refurbished',
            disabled: false
        },
        {
            value: 'Undefined',
            label: 'Undefined',
            disabled: false
        }
    ];
}

export const uiDeviceType = () => {
    return [
        {
            value: 'bp',
            label: 'BLOOD PRESSURE',
            disabled: false
        },
        {
            value: 'bg',
            label: 'BLOOD GLUCOSE',
            disabled: false
        },
        {
            value: 'n',
            label: 'NUTRITION',
            disabled: false
        },
    ];
}

export const uploadFilesTableColumns = () => {
    return [
        { text: i18n.t('column.uploadedFiles.originalFileName'), value: "fileOriginalName", sortable: false, },
        { text: i18n.t('column.uploadedFiles.uploadDate'), value: "regDateLabel", sortable: true, align: "center", width: "180" },
        { text: i18n.t('column.uploadedFiles.remove'), value: "consentRemoveBtn", sortable: false, align: "center", width: "100" },
    ];
}

export const consentFilesTableColumns = () => {
    return [
        { text: "Original File Name", value: "fileOriginalName", sortable: false, },
        { text: "consent Date", value: "consentDateLabel", sortable: true, align: "center", width: "120" },
        { text: "Upload Date", value: "regDateLabel", sortable: true, align: "center", width: "180" },
        { text: "remove", value: "consentRemoveBtn", sortable: false, align: "center", width: "100" },
    ];
}

export const ICDCodeTableColumns = () => {
    return [
        {
            key: 'selected',
            title: '',
            dataIndex: 'selected',
            width: 70,
            scopedSlots: { customRender: 'selected' }
        },
        {
            key: 'code',
            title: 'code',
            dataIndex: 'code',
            width: 100,
            scopedSlots: { customRender: 'code' }
        },
        {
            key: 'label',
            title: 'label',
            dataIndex: 'label',
            ellipsis: true,
            align: 'left',
            scopedSlots: { customRender: 'label' }
        },
        // {
        //     key: 'tag',
        //     title: 'tag',
        //     dataIndex: 'tag',
        //     ellipsis: true,
        //     align: 'left',
        //     scopedSlots: { customRender: 'tag' }
        // },
    ];
}

export const monthEnType = () => {
    return [
        {
            value: "01",
            text: "January"
        },
        {
            value: "02",
            text: "February"
        },
        {
            value: "03",
            text: "March"
        },
        {
            value: "04",
            text: "April"
        },
        {
            value: "05",
            text: "May"
        },
        {
            value: "06",
            text: "June"
        },
        {
            value: "07",
            text: "July"
        },
        {
            value: "08",
            text: "August"
        },
        {
            value: "09",
            text: "September"
        },
        {
            value: "10",
            text: "October"
        },
        {
            value: "11",
            text: "November"
        },
        {
            value: "12",
            text: "December"
        },
    ]
}

export const useTypeItems = () => {
    return [
        { value: "Y", label: "Y", color: "" },
        { value: "N", label: "N", color: "" },
    ];
}

export const userPermitItems = () => {
    return [
        { value: "Y", label: "Y", color: "" },
        { value: "N", label: "N", color: "" },
    ];
}

export const genderTypeItems = () => {
    return [
        { value: "MALE", label: i18n.t('common.component.gender.MALE'), color: "" },
        { value: "FEMALE", label: i18n.t('common.component.gender.FEMALE'), color: "red" },
        // { value: "UNDEFINED", label: "UNDEFINED", color: "" },
    ];
}

export const localeTypeItems = () => {
    return [
        { value: "ko", label: "한국어", color: "red" },
        { value: "en", label: "영어", color: "" },
        { value: "cn", label: "중국어", color: "" },
    ];
}

export const serviceTypeItems = () => {
    return [
        { value: "RPM", label: "RPM", disabled: true, color: "" },
        { value: "CCM", label: "CCM", disabled: false, color: "" },
    ];
}

export const patientTypeItems = () => {
    return [
        { value: "provisional", label: i18n.t('patient.detail.patientType.provisional'), color: "" },
        { value: "consent", label: i18n.t('patient.detail.patientType.consent'), color: "" },
    ];
}

export const sendTypeItems = () => {
    return [
        { value: "sms", label: "SMS", color: "red" },
        { value: "kakaoAlimtok", label: "kakao alimtok", color: "" },
        { value: "Email", label: "Email", color: "" },
    ];
}

export const paymentTableColumnsV1 = () => {
    return [
        { text: "상품명", value: "productNameKr", sortable: false, align: "left" },
        { text: "상품타입", value: "productTypeName", sortable: false, align: "left", width: "100" },
        { text: "아이템명", value: "productTypeItemName", sortable: false, align: "left", width: "120" },
        { text: "상품가격", value: "productPriceLbl", sortable: false, align: "center", width: "80" },
        { text: "판매가격", value: "productSalePriceLbl", sortable: false, align: "center", width: "80" },
        { text: "텍스 리펀드", value: "isCanTaxRefund", sortable: false, align: "center", width: "80" },
        // { text: "", value: "paidValue", sortable: false, align: "center", width: "120" },
    ];
}

export const bbsInfoTableColumns = () => {
    return [
        { text: "게시판타입", value: "bbsGbn", sortable: false, align: "left", width: "120" },
        { text: "글제목", value: "titleKo", sortable: false },
        { text: "첨부파일수", value: "uploadFileNum", sortable: false, align: "center", width: "80" },
        { text: "업데이트일", value: "dpDateLabel", sortable: true, align: "center", width: "200" },
    ];
}

export const coalitionTableColumns = () => {
    return [
        { text: "담당자이름", value: "contactMan", sortable: false, align: "center", width: "80" },
        { text: "담당자직책", value: "contactManPos", sortable: false, align: "center", width: "80" },
        { text: "회사명", value: "contactManCompany", sortable: false, align: "center", width: "120" },
        { text: "담당자이메일", value: "contactManEmail", sortable: false, align: "left" },
        { text: "담당자휴대폰", value: "contactManCell", sortable: false, align: "center", width: "150" },
        { text: "업데이트일", value: "dpDateLabel", sortable: true, align: "center", width: "200" },
    ];
}

export const hospitalApplyTableColumns = () => {
    return [
        { text: "병원명", value: "hospitalName", sortable: false, align: "left", width: "150" },
        { text: "병원부서", value: "hospitalDepartment", sortable: false },
        { text: "담당자이름", value: "contactMan", sortable: false, align: "center", width: "80" },
        { text: "담당자이메일", value: "contactManEmail", sortable: false, align: "center", width: "150" },
        { text: "담당자휴대폰", value: "contactManCell", sortable: false, align: "center", width: "150" },
        { text: "업데이트일", value: "dpDateLabel", sortable: true, align: "center", width: "200" },
    ];
}

export const patientInfoTableColumnsV1 = () => {
    return [
        { text: "", value: "editOpen", sortable: false, align: "center", width: "50" },
        // { text: "Memo", value: "memo", sortable: false, align: "center", width: "50" },
        { text: i18n.t('column.patient.name'), value: "hospitalName", sortable: true },
        { text: i18n.t('column.patient.name'), value: "cardTitle", sortable: true },
        { text: i18n.t('column.patient.gender'), value: "genderShort", sortable: true, align: "center", width: "80" },
        { text: i18n.t('column.patient.age'), value: "age", sortable: true, align: "center", width: "60" },
        // { text: "Diagnosis", value: "diagnosisType", sortable: false, align: "center", width: "60" },
        // { text: i18n.t('column.patient.service'), value: "serviceType", sortable: false, align: "center", width: "70" },
        // { text: "CCM last call", value: "CCMLastCall", sortable: false, align: "center", width: "120" },
        // { text: "CCM turn", value: "CCMTurn", sortable: false, align: "center", width: "50" },
        // { text: "CCM CareTime", value: "ccmCareTimeLabel", sortable: true, align: "center", width: "100" },
        // { text: "CCM Claims", value: "CCMClaims", sortable: false, align: "center", width: "50" },
        // { text: "CCM Success Call", value: "CCMSuccessCall", sortable: false, align: "center", width: "100" },
        // { text: "CCM Fail Call", value: "CCMFailCall", sortable: false, align: "center", width: "80" },
        // { text: "RPM ElaspedDate", value: "RPMElaspedDate", sortable: false, align: "center", width: "100" },
        // { text: "RPM Measure", value: "RPMMeasure", sortable: true, align: "center", width: "90" },
        // { text: "RPM CareTime", value: "RPMCareTime", sortable: true, align: "center", width: "100" },
        // { text: "Remained Alert", value: "RemainedAlert", sortable: true, align: "center", width: "100" },
        { text: i18n.t('column.clinic.regDate'), value: "regDateLabel", sortable: true, align: "center", width: "200" },
    ];
}

export const headOfficeInfoTableColumnsV1 = () => {
    return [
        { text: "Head Office Name", value: "officeName", sortable: true, width: "150" },
        { text: "E-mail", value: "email", sortable: false, align: "left" },
        { text: "Mobile", value: "cell", sortable: false, align: "center", width: "100" },
        { text: "registration Date", value: "regDateLabel", sortable: true, align: "center", width: "200" },
    ];
}

export const clinicInfoTableColumnsV1 = () => {
    return [
        // { text: "", value: "clinicProductOpen", sortable: false, align: "center", width: "50" },
        { text: i18n.t('column.clinic.eligible'), value: "eligible", sortable: true, align: "center", width: "90" },
        { text: i18n.t('column.clinic.name'), value: "hospitalName", sortable: true, width: "150" },
        { text: i18n.t('column.clinic.email'), value: "email", sortable: false, align: "left" },
        { text: i18n.t('column.clinic.mobile'), value: "cell", sortable: false, align: "center", width: "100" },
        { text: i18n.t('column.clinic.regDate'), value: "regDateLabel", sortable: true, align: "center", width: "200" },
    ];
}

export const paidLogTableColumns = () => {
    return [
        { text: "처리일자", value: "regDateLabel", sortable: true, align: "left", width: "170" },
        { text: "지불한내용", value: "productNameKr", sortable: false },
        { text: "지불한금액", value: "paidValueLabel", sortable: false, align: "center" },
        { text: "수수료", value: "chargeLabel", sortable: false, align: "center", width: "100" },
        { text: "수술/시술/이벤트", value: "chargeType", sortable: false, align: "center", width: "100" },
        { text: "관리자", value: "adminId", sortable: false, align: "center", width: "180" },
    ];
}

export const staffTableColumnsV1 = () => {
    return [
        { text: i18n.t('column.staff.name'), value: "staffName", sortable: true, width: "150" },
        { text: i18n.t('column.staff.loginId'), value: "loginId", sortable: false, align: "center" },
        { text: i18n.t('column.staff.type'), value: "staffType", sortable: false, align: "center", width: "100" },
        { text: i18n.t('column.staff.isPhoto'), value: "isPhoto", sortable: false, align: "center", width: "100" },
        { text: i18n.t('column.clinic.regDate'), value: "regDateLabel", sortable: true, align: "center", width: "200" },
    ];
}

export const productTypeTableColumnsV1 = () => {
    return [
        { text: "병원 상품", value: "productNameKr", sortable: false },
        { text: "", value: "bookingTypeValue", sortable: false, align: "center", width: "180" },
    ];
}

export const methodTypeItems = () => {
    return [
        { value: '시술', label: "시술" },
        { value: '수술', label: "수술" },
    ]
};

export const isCanTaxRefundItem = () => {
    return [
        { value: 'Y', label: "텍스리펀(Y)" },
        { value: 'N', label: "텍스리펀(N)" },
    ]
};

export const hospitalTypeItem = () => {
    return [
        { value: '', text: '[전체]' },
        { value: 'severe', text: '종합병원' },
        { value: 'plastic', text: '전문병원(성형외과)' },
    ]
};