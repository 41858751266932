<template>
  <v-dialog v-model="visible" max-width="500px" scrollable>
    <v-card class="editorPadding">
      <Header @close="close" />

      <v-card-title>
        <!-- 환자 정보 수정 -->
        회원가입정보
        {{
          userData && userData.user_id > 0
            ? $t("common.titleUpdate")
            : $t("common.titleRegistration")
        }}
      </v-card-title>

      <PatientAddForm ref="refPatientAddForm" :patientData="userData" />

      <BtnBox
        :dataId="userData && userData.user_id"
        :activated="userData && userData.activated"
        @save="saveUser"
        @active="activeUser"
      />
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
import Header from "./comp/Header.vue";
import BtnBox from "./comp/BtnBox.vue";
import PatientAddForm from "./form/PatientAddForm.vue";
const formatService = require("@/utils/format.js");
const modelService = require("@/utils/dataModel");
const rulesService = require("@/utils/rules.js");
const requireService = require("@/utils/requiredValue.js");

export default {
  components: {
    Header,
    BtnBox,
    PatientAddForm,
  },
  data() {
    return {
      visible: false,
      confirmLoading: false,
      // staffTypeList: formatService.staffType(),
      // idRules: rulesService.idRules(),
      // passwordRules: rulesService.passwordRules(),
      // emailRules: rulesService.emailRules(),
      birthdayRules: rulesService.birthdayRules(),
      SSNRules: rulesService.SSNRules(),
      requiredMsg: requireService.requiredFieldAlertMessage(),
      userData: null,
      reqData: modelService.reqDataModel(),
    };
  },
  methods: {
    Open(userData, hospital_id) {
      console.log("userData", userData);
      console.log("hospital_id", hospital_id);

      this.visible = true;

      // 초기화
      this.userData = modelService.patientModel();
      this.reqData.user_id = userData.user_id;
      this.reqData.hospital_id = hospital_id;
      this.userData.hospital_id = hospital_id;
      if (userData.user_id > 0) this.getPatientData();

      // top으로
      // this.$vuetify.goTo(0);
      // document.form.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setTimeout(() => {
        document
          .getElementById("patientAddForm")
          .scrollIntoView({ behavior: "smooth" });
      }, 300);
    },
    close() {
      this.visible = false;
    },
    // 데이터읽기
    async getPatientData() {
      const $h = this.$helper;
      this.reqData.endpoint = "patientOne";
      await this.getDataQuery(this.reqData).then((res) => {
        if (res === undefined) return;
        let resData = res;
        // 서비스 타입
        // resData.careType = [];
        // resData.careType = $h.changeStringToArr(resData.serviceType);

        // 최신신바이오정보(키/몸무개)
        // if (resData.patientBioInfoHeight !== null) {
        //   resData.height = $h.dataValidate(resData.patientBioInfoHeight.value);
        //   resData.heightUnit = $h.dataValidate(
        //     resData.patientBioInfoHeight.unit
        //   );
        // }
        // if (resData.patientBioInfoWeight !== null) {
        //   resData.weight = $h.dataValidate(resData.patientBioInfoWeight.value);
        //   resData.weightUnit = $h.dataValidate(
        //     resData.patientBioInfoWeight.unit
        //   );
        // }
        // 최신주소 처리
        // resData.zipcode = "";
        // resData.city = "";
        // resData.street = "";
        // if (resData.patientRecentContact !== null) {
        //   resData.country = $h.dataValidate(
        //     resData.patientRecentContact.country
        //   );
        //   resData.cell = $h.dataValidate(resData.patientRecentContact.cell);
        //   resData.phone = $h.dataValidate(resData.patientRecentContact.phone);
        //   if (resData.patientRecentContact.address !== null) {
        //     resData.zipcode = $h.dataValidate(
        //       resData.patientRecentContact.address.zipcode
        //     );
        //     resData.address1 = $h.dataValidate(
        //       resData.patientRecentContact.address.address1
        //     );
        //     resData.address2 = $h.dataValidate(
        //       resData.patientRecentContact.address.address2
        //     );
        //     resData.city = $h.dataValidate(
        //       resData.patientRecentContact.address.city
        //     );
        //     resData.street = $h.dataValidate(
        //       resData.patientRecentContact.address.street
        //     );
        //   }
        // }
        // 등록일
        resData.registrationDate = resData.registrationDate === undefined ? "":$h.dateValidate(resData.registrationDate);

        // 최신 비상주소
        // if (resData.patientEmergencyContact != null) {
        //   resData.emergencyContactName = $h.dataValidate(
        //     resData.patientEmergencyContact.emergencyContactName
        //   );
        //   resData.emergencyContactPhone = $h.dataValidate(
        //     resData.patientEmergencyContact.emergencyContactPhone
        //   );
        //   resData.emergencyContactRelation = $h.dataValidate(
        //     resData.patientEmergencyContact.emergencyContactRelation
        //   );
        // }
        console.log("resData", resData);
        const img = resData.userPhotoImg;
        if (img !== "" && img !== null) {
          resData.userPhotoFileInfo = {
            filename: $h.findFileName(img),
            fullURL: img,
            isImageFile: $h.checkImageFile($h.findFileName(img)),
          };
        }

        // patientAddInfo1 null 처리
        // if (resData.patientAddInfo1 === null) {
        //   resData.patientAddInfo1 = {};
        //   resData.patientAddInfo1.nameCn = "";
        //   resData.patientAddInfo1.passportNumber = "";
        //   resData.patientAddInfo1.hopeSurgeryDay1 = "";
        //   resData.patientAddInfo1.hopeSurgeryDay2 = "";
        //   resData.patientAddInfo1.wechatID = "";
        // }

        // patientAddInfo2 null 처리
        // if (resData.patientAddInfo2 === null) {
        //   resData.patientAddInfo2 = {};
        //   resData.patientAddInfo2.surgeryClinic = "";
        //   resData.patientAddInfo2.surgeryProduct = "";
        //   resData.patientAddInfo2.prepaid = 0;
        //   resData.patientAddInfo2.paidInKorea = 0;
        //   resData.patientAddInfo2.paidUnit = "";
        // }

        this.userData = resData;
        this.userData.checkedSsn = resData.ssn;
        this.userData.checkedMobile = resData.cell;
        this.userData.duplicateSSNChecked = true;
        this.userData.duplicateMobileChecked = true;
        this.userData.disabledCityAndState = false;
        // icdCode object로 전달~
        this.userData.icdCodeObj = { icdCode: this.userData.icdCode };

        console.log("this.userData", this.userData);
      });
    },
    saveUser() {
      // 필수처리(이메일주소로 중복 확인 중~)
      const $h = this.$helper;
      const D = this.userData;
      let RF = this.$refs.refPatientAddForm.$refs;
      let chk;
      let tOj;
      let msg;
      console.log("patientData : ", D);
      // 필수 체크
      if (!RF.form.validate()) return;

      RF = RF.refProfile.$refs;
      // msg = `Service ${this.requiredMsg}`;
      // if (!$h.requiredCheck(D.serviceType,null,msg,false)) return;

      // msg = `DiagnosisType ${this.requiredMsg}`;
      // if (!$h.requiredCheck(D.diagnosisType, null, msg, false)) return;

      // msg = `Icd Code ${this.requiredMsg}`;
      // if (!$h.requiredCheck(D.icdCode, null, msg, false)) return;

      // if (!this.$refs.refPatientAddForm.$refs.form.validate()) return;

      // SSN중복확인
      console.log("", D);
      // if (!D.duplicateSSNChecked) {
      //   msg = `Please check for duplicates SSN.`;
      //   $h.showTostMessage("", msg, "error");
      //   return;
      // }

      // country 필수
      // msg = `국가 ${this.requiredMsg}`;
      // if (!$h.requiredCheck(D.country, null, msg, false)) return;

      // 연락처 관계 필수
      // msg = `비상연락처(관계) ${this.requiredMsg}`;
      // if (!$h.requiredCheck(D.emergencyContactRelation, null, msg, false))
      //   return;

      // 주치의 필수
      // msg = `PRIMARY PHYSICIAN ${this.requiredMsg}`;
      // if (!$h.requiredCheck(D.primaryPhysician, null, msg, false)) return;

      // 최종저장
      // D.registrationDate = $h.changeDateToString(D.registrationDate, true);
      // console.log("", D);
      // return;
      const saveOrUpdate = D.user_id > 0 ? "update" : "save";
      if (!confirm(`${saveOrUpdate} Patient information?`)) return;
      this.saveAction(D);
    },
    async saveAction(data) {
      const $h = this.$helper;
      data.endpoint = "userMemberUpdate";
      this.saveDataQuery(data).then((res) => {
        if (res === undefined) return;
        $h.showTostMessage("", $h.commonMessage("S"), "success");
        this.visible = false;
        this.$emit("complete", res);
      });
    },
    activeUser(option) {
      if (!confirm("UnActive?")) return;
      this.userData.activated = option;
      this.saveAction(this.userData);
    },
    ...mapActions(["getDataQuery", "saveDataQuery"]),
  },
};
</script>
<style lang="scss">
.editorPadding {
  padding: 40px;
}
.v-input {
  padding-top: 0 !important;
}
</style>