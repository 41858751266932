<template>
  <v-card-text>
    <v-form ref="form">
      <v-card-text>
        <v-text-field
          class="pt-3"
          ref="refContactMan"
          v-model="detailData.contactMan"
          label="담당자"
          placeholder="담당자"
          disabled
        />

        <v-text-field
          class="pt-3"
          ref="refContactManCompany"
          v-model="detailData.contactManCompany"
          label="회사명"
          placeholder="회사명"
          disabled
        />

        <v-text-field
          class="pt-3"
          ref="refContactManPos"
          v-model="detailData.contactManPos"
          label="담당자직책"
          placeholder="담당자직책"
          disabled
        />

        <v-text-field
          class="pt-3"
          ref="refContactManEmail"
          v-model="detailData.contactManEmail"
          label="담당자이메일"
          placeholder="담당자이메일"
          disabled
        />

        <v-text-field
          class="pt-3"
          ref="refContactManCell"
          v-model="detailData.contactManCell"
          label="담당자휴대폰"
          placeholder="담당자휴대폰"
          disabled
        />

        <v-textarea
          class="pt-3"
          counter="1000"
          maxLength="1000"
          v-model="detailData.coalitionText"
          label="담당자휴대폰"
          placeholder="담당자휴대폰"
          :outlined="true"
          disabled
        />
      </v-card-text>
    </v-form>
  </v-card-text>
</template>
<script>

export default {
  components: { },
  props: {
    detailData: {
      type: [Object, Array],
    },
  },
  data() {
    return {
    };
  },
  methods: {},
};
</script>
<style lang="scss">
.headTitle {
  font-size: 20px;
  font-weight: 700;
  color: darkblue;
}
.hGap2 {
  height: 50px !important;
}
.hGap {
  height: 20px !important;
}
.subMain {
  width: 100%;
  ._vGap {
    height: 10px;
  }
  .edit_vGap {
    height: 7px;
  }
}
</style>