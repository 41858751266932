<template>
  <div class="subMain">
    <!-- <div class="headTitle">{{ $t("patient.profileTitle") }}</div> -->
    <!-- <div class="patient_vGap"></div>
    <div class="patientEdit_vGap" v-if="patientData.user_id === 0"></div> -->
    <v-card-text>
      <v-row>
        <v-col>
          <S3FileUpload
            ref="userImgS3FileUploader"
            uploadType="profile"
            accept="image/png, image/jpeg, image/bmp, image/gif"
            defLabel="환자사진(이미지)"
            uploadDir="users"
            fileHeader="profileImg"
            defImage="profile_man"
            :fileDeleteOpt="fileDeleteOpt"
            :fileInfo="patientData.userPhotoFileInfo"
            @uploadComplete="imgUploadComplete"
            @changeDeleteOpt="imgChangeDeleteOpt"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <SelectBox
            selectStyleWidth="width: 100%"
            ref="refCountry"
            :value="patientData.cellNation"
            :items="countryItems"
            :required="true"
            @changeSelect="patientData.cellNation = $event"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            label="휴대폰번호"
            width="100%"
            placeholder="휴대폰번호"
            ref="refCell"
            v-model="patientData.cell"
            :rules="[filedRules.required]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            label="이름"
            width="100%"
            placeholder="이름"
            ref="refName"
            v-model="patientData.name"
            :rules="[filedRules.required]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <RadioComps
            :prosIsRow="true"
            :items="genderData"
            :prosSelected="patientData.gender"
            @changeType="patientData.gender = $event"
          />
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col>
          <v-text-field
            :label="$t('patient.detail.nameCN')"
            :placeholder="$t('patient.detail.nameCN')"
            ref="refNameCN"
            v-model="patientData.patientAddInfo1.nameCn"
          />
        </v-col>
      </v-row> -->
      <!-- <v-row>
        <v-col>
          <v-text-field
            label="Nickname"
            ref="refNickname"
            v-model="data.nickname"
            placeholder="Nickname"
          />
        </v-col>
      </v-row> -->
      <!-- <v-row>
        <v-col>
          <v-text-field
            :label="$t('patient.detail.passportNumber')"
            :placeholder="$t('patient.detail.passportNumber')"
            ref="refPassportNumber"
            v-model="patientData.patientAddInfo1.passportNumber"
            :rules="[
              passportRules.required,
              passportRules.counter,
              passportRules.check,
            ]"
          />
        </v-col>
      </v-row> -->
      <v-row>
        <v-col>
          <SelectBirthday
            ref="refBirthday"
            :value="patientData.birthday"
            @updateSelect="patientData.birthday = $event"
          />
        </v-col>
      </v-row>
      <PatientDescription :data="patientData" />
    </v-card-text>
  </div>
</template>
<script>
import SelectBox from "@/components/commonV2/ui/SelectBox.vue";
import RadioComps from "@/components/commonV2/ui/RadioComps.vue";
import SelectBoxForTypeList from "@/components/commonV2/ui/SelectBoxForTypeList.vue";
import SelectBoxICDCode from "@/components/commonV2/ui/SelectBoxICDCode.vue";
import SelectBirthday from "@/components/commonV2/ui/SelectBirthday.vue";
import DuplCheckType from "@/components/commonV2/ui/DuplCheckType.vue";
import InputName3Type from "@/components/commonV2/ui/InputName3Type.vue";
import S3FileUpload from "@/components/commonV2/ui/S3_ForFileUpload.vue";
import PatientDescription from "./PatientDescription.vue";

const formatService = require("@/utils/format.js");
const rulesService = require("@/utils/rules.js");
const requireService = require("@/utils/requiredValue.js");

export default {
  components: {
    SelectBox,
    RadioComps,
    SelectBoxForTypeList,
    SelectBoxICDCode,
    SelectBirthday,
    DuplCheckType,
    InputName3Type,
    S3FileUpload,
    PatientDescription,
  },
  props: {
    patientData: {
      type: [Object, Array],
    },
    staffTypeList: {
      type: [Object, Array],
    },
  },
  // watch: {
  //   patientData(val) {
  //     this.country = val.country;
  //   },
  // },
  data() {
    return {
      filedRules: rulesService.filedRules(),
      diagnosisList: formatService.diagnosisList(),
      genderValue: "MALE", // 초기값
      genderData: formatService.genderTypeItems(),
      // selectWeight: "kg",
      // weightList: formatService.weightList(),
      // selectHeight: "cm",
      // heightList: formatService.heightList(),
      imgFileInfo: null,
      fileDeleteOpt: false,
      fileDeleteRequestImg: false, // 저장 실행여부(파일삭제처리 오류)
      // SSNRules: rulesService.SSNRules(),
      passportRules: rulesService.passportRules(),
      requiredMsg: requireService.requiredFieldAlertMessage(),
      patientTypeList: formatService.patientTypeList(),
      // country: this.data.country,
      countryItems: formatService.countryList(),
    };
  },
  mounted() {
    this.fileDeleteOpt = false;
    this.imgFileInfo = null;
    this.psdFileInfo = null;
    this.fileDeleteRequestImg = false;

    setTimeout(() => {
      this.$refs.userImgS3FileUploader._reset();
    }, 300);
  },
  methods: {
    imgChangeDeleteOpt(e) {
      this.fileDeleteRequestImg = e;
    },
    // S3업로드 처리 후
    imgUploadComplete(e) {
      console.log("imgUploadComplete", e);
      // console.log('첨부파일이 선택되지 않은경우임 -> 다음단계로 이동 처리');
      if (e !== null) {
        this.data.userPhotoImg = e.Location;
        this.data.userPhotoFileInfo = {
          filename: this.$helper.findFileName(e.key),
          fullURL: e.Location,
          isImageFile: this.$helper.checkImageFile(
            this.$helper.findFileName(e.key)
          ),
        };
      }
      console.log("this.data.userPhotoFileInfo", this.data.userPhotoFileInfo);
      this.$forceUpdate();
    },
    // onChangeGender(e) {
    //   console.log('e', e);
      
    //   // this.data.gender = e; //.target.value;
    // },
    // onChangeCountry(e) {
    //   this.country = e;
    //   this.data.country = e;
    // },
    // dobSelectedComplete(e) {
    //   this.data.dob = e;
    // },
    // ssnChange() {
    //   console.log("ssnChange");
    //   this.data.duplicateChecked = false;
    //   this.$forceUpdate();
    // },
    // updateSelectDiagnosis(e) {
    //   this.data.diagnosisType = e;
    // },
    // changeInputValueHeightHandler(e) {
    //   this.data.height = e.target.value;
    // },
    // updateSelectHeightHandler(e) {
    //   this.data.heightUnit = e;
    // },
    // changeInputValueWeightHandler(e) {
    //   this.data.weight = e.target.value;
    // },
    // updateSelectWeightHandler(e) {
    //   this.data.weightUnit = e;
    // },
    // selectedIcdCodeComplete(e) {
    //   this.data.icdCode = e.data;
    // },
    // genderChangeType(e) {
    //   this.data.gender = e;
    // },
  },
};
</script>
<style lang="scss">
.patient_vGap {
  height: 10px;
}
.patientEdit_vGap {
  height: 7px;
}
</style>