<template>
  <div class="subMain">
    <v-card-text>

      <v-combobox
        label="게시판타입(선택)"
        v-model="bbsTypeArr"
        :items="itemsBbsType"
        @change="bbsData.bbsGbn = $event"
      />

      <TitleField 
        ref="refTitleField"
        :label="`${bbsData.bbsGbn} 글제목`" 
        :placeholder="`${bbsData.bbsGbn} 글제목`"
        :titleData="bbsData.title" 
        :rules="[filedRules.required, filedRules.counter]" 
        @complete="bbsData.title = $event"/>

      <TextArea 
        :label="`${bbsData.bbsGbn} 글내용`" 
        :placeholder="`${bbsData.bbsGbn} 글내용`"
        :isOutlined="false"
        :titleData="bbsData.contents" 
        :rules="[filedRules.required]"
        @complete="bbsData.contents = $event"/>

      <v-row class="pt-5" v-if="bbsData.bbsGbn === '뉴스'">
        <v-text-field
          ref="refNewsUrl"
          label="뉴스URL"
          placeholder="https://www~"
          v-model="bbsData.vodLink"
          :rules="[filedRules.required]" 
        />
      </v-row>
    </v-card-text>
  </div>
</template>
<script>
import TitleField from "@/components/commonV2/ui/lang/TitleField.vue";
import TextArea from "@/components/commonV2/ui/lang/TextArea.vue";
const formatService = require("@/utils/format.js");
const rulesService = require("@/utils/rules.js");

export default {
  components: {
    TitleField,
    TextArea
  },
  props: {
    bbsData: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      bbsTypeArr: [],
      itemsBbsType: formatService.itemsBbsType(),
      filedRules: rulesService.filedRules(),
    };
  },
  methods: {
    _reset(bbsGbn) {      
      this.bbsTypeArr = [bbsGbn === undefined? '': bbsGbn];
    }
  },
};
</script>
<style lang="scss">
</style>