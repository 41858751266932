<template>
  <v-card-text>
    <v-form ref="form">
      <v-row justify="start" align="start">
        <v-col cols="12">
          <BbsInfo ref="refBbsInfo" :bbsData="bbsData" />

          <div class="hGap" />

        </v-col>
      </v-row>
    </v-form>

    <BbsFilesUpload
      ref="refBbsFilesUpload"
      :bbsData="bbsData"
    /> 
  </v-card-text>
</template>
<script>
import BbsInfo from "./bbs/BbsInfo.vue";
import BbsFilesUpload from "./bbs/BbsFilesUpload.vue";

export default {
  components: {
    BbsInfo,
    BbsFilesUpload
  },
  props: {
    bbsData: {
      type: [Object, Array],
    },
  },
  data() {
    return {
    };
  },
  methods: {},
};
</script>
<style lang="scss">
.headTitle {
  font-size: 20px;
  font-weight: 700;
  color: darkblue;
}
.hGap2 {
  height: 50px !important;
}
.hGap {
  height: 20px !important;
}
.subMain {
  width: 100%;
  ._vGap {
    height: 10px;
  }
  .edit_vGap {
    height: 7px;
  }
}
</style>